import { useState } from 'react';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import FormControl from '@mui/joy/FormControl';
import FormLabel, { formLabelClasses } from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Typography from '@mui/joy/Typography';
import ReportIcon from '@mui/icons-material/Report';
import Alert from '@mui/joy/Alert';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import IconButton from '@mui/joy/IconButton';
import Stack from '@mui/joy/Stack';
import LinearProgress from '@mui/joy/LinearProgress';
import Key from '@mui/icons-material/Key';
import GoogleIcon from '../elements/GoogleIcon';
import { useNavigate } from 'react-router-dom';
import Link from '@mui/material/Link';

import { createUserWithEmailAndPassword, sendEmailVerification } from "firebase/auth";
import { auth } from '../firebase';

export default function Signup() {
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const [password, setPassword] = useState('');
    const [confirmPassword, setconfirmPassword] = useState('');
    const minLength = 12;


    const handleSignUp = (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        const fullName = formData.get("name");
        const email = formData.get("email");
        const password = formData.get("password");
        const confirmPassword = formData.get("confirmPassword");

        if (password !== confirmPassword) {
            setError("Passwords don't match!");
            return;
        }

        // Call the createUserWithEmailAndPassword method on the authentication instance
        createUserWithEmailAndPassword(auth, email, password)
            .then(async (userCredential) => {
                // User signed up successfully
                const user = userCredential.user;

                // Send a verification email to the user
                await sendVerificationEmail(user);

                setTimeout(() => { console.log('Wait Finished'); }, 2000);

                await navigate("/notVerified");

            })
            .catch((error) => {
                // Handle errors
                const errorCode = error.code;
                setError(errorCode);
            });
    };

    // Send a verification email to the user
    const sendVerificationEmail = (user) => {
        sendEmailVerification(user)
            .then(() => {
                console.log("Verification email sent to:", user.email);
            })
            .catch((error) => {
                console.error("Error sending verification email:", error);
            });
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Box
                sx={(theme) => ({
                    height: '100%',
                    position: 'fixed',
                    right: 'clamp(0px, (100vw - var(--Collapsed-breakpoint)) * 999, 100vw - var(--Cover-width))',
                    top: 0,
                    bottom: 0,
                    left: 0,
                    zIndex: 1,
                    display: 'flex',
                    justifyContent: 'flex-end',
                    backdropFilter: 'blur(4px)',
                    backgroundColor: 'white'
                })}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        minHeight: '100dvh',
                        width:
                            'clamp(var(--Form-maxWidth), (var(--Collapsed-breakpoint) - 100vw) * 999, 100%)',
                        maxWidth: '100%',
                        px: 10,
                    }}
                >
                    <Box
                        component="header"
                        sx={{
                            py: 3,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Typography
                            fontWeight="lg"
                            startDecorator={
                                <Box
                                    component="span"
                                    sx={{
                                        width: 24,
                                        height: 24,
                                        background: (theme) =>
                                            `linear-gradient(45deg, ${theme.vars.palette.primary.solidBg}, ${theme.vars.palette.primary.solidBg} 30%, ${theme.vars.palette.primary.softBg})`,
                                        borderRadius: '50%',
                                        boxShadow: (theme) => theme.shadow.md,
                                        '--joy-shadowChannel': (theme) =>
                                            theme.vars.palette.primary.mainChannel,
                                    }}
                                />
                            }
                        >
                            Exam Eve
                        </Typography>
                    </Box>
                    <Box
                        component="main"
                        sx={{
                            my: 'auto',
                            py: 2,
                            pb: 5,
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 2,
                            width: 400,
                            maxWidth: '100%',
                            mx: 'auto',
                            borderRadius: 'sm',
                            '& form': {
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 2,
                            },
                            [`& .${formLabelClasses.asterisk}`]: {
                                visibility: 'hidden',
                            },
                        }}
                    >
                        <div>
                            <Typography component="h1" fontSize="xl2" fontWeight="lg">
                                Sign up
                            </Typography>
                        </div>
                        <form onSubmit={handleSignUp}>
                            <FormControl required>
                                <FormLabel>Email</FormLabel>
                                <Input type="email" name="email" />
                            </FormControl>
                            <FormControl required>
                                <FormLabel>Password</FormLabel>
                                <Stack
                                    spacing={0.5}
                                    sx={{'--hue': Math.min(password.length * 10, 120),}}
                                >
                                    <Input
                                        type="password"
                                        name="password"
                                        placeholder="Type in here…"
                                        startDecorator={<Key />}
                                        value={password}
                                        onChange={(event) => setPassword(event.target.value)}
                                    />
                                    <LinearProgress
                                        determinate
                                        size="sm"
                                        value={Math.min((password.length * 100) / minLength, 100)}
                                        sx={{
                                        bgcolor: 'background.level3',
                                        color: 'hsl(var(--hue) 80% 40%)',
                                        }}
                                    />
                                    <Typography
                                        level="body-xs"
                                        sx={{ alignSelf: 'flex-end', color: 'hsl(var(--hue) 80% 30%)' }}
                                    >
                                        {password.length < 3 && 'Very weak'}
                                        {password.length >= 3 && password.length < 6 && 'Weak'}
                                        {password.length >= 6 && password.length < 10 && 'Strong'}
                                        {password.length >= 10 && 'Very strong'}
                                    </Typography>
                                </Stack>
                            </FormControl>
                            <FormControl required>
                                <FormLabel>Confirm password</FormLabel>
                                <Stack
                                    spacing={0.5}
                                    sx={{'--hue': Math.min(confirmPassword.length * 10, 120),}}
                                >
                                    <Input
                                        type="password"
                                        name="confirmPassword"
                                        placeholder="Type in here…"
                                        startDecorator={<Key />}
                                        value={confirmPassword}
                                        onChange={(event) => setconfirmPassword(event.target.value)}
                                    />
                                    <LinearProgress
                                        determinate
                                        size="sm"
                                        value={Math.min((confirmPassword.length * 100) / minLength, 100)}
                                        sx={{
                                        bgcolor: 'background.level3',
                                        color: 'hsl(var(--hue) 80% 40%)',
                                        }}
                                    />
                                    <Typography
                                        level="body-xs"
                                        sx={{ alignSelf: 'flex-end', color: 'hsl(var(--hue) 80% 30%)' }}
                                    >
                                        {confirmPassword.length < 3 && 'Very weak'}
                                        {confirmPassword.length >= 3 && confirmPassword.length < 6 && 'Weak'}
                                        {confirmPassword.length >= 6 && confirmPassword.length < 10 && 'Strong'}
                                        {confirmPassword.length >= 10 && 'Very strong'}
                                    </Typography>
                                </Stack>
                            </FormControl>
                            <Button type="submit" fullWidth>
                                Sign Up
                            </Button>
                        </form>
                        {/* <Button
                            variant="outlined"
                            color="neutral"
                            fullWidth
                            startDecorator={<GoogleIcon />}
                        >
                            Sign up with Google
                        </Button> */}
                        <Typography sx={{ fontStyle: 'italic', fontSize: '12px' }}>
                            By selecting Create account, you agree to our  
                            <Link href="/terms-and-conditions" color="blue" underline="none"> Terms and Conditions </Link> 
                            and have read and acknowledged our  
                            <Link href="/privacy-policy" color="blue" underline="none"> Privacy Policy</Link>.
                        </Typography>
                        {
                            error !== null &&
                            <Box sx={{ display: 'flex', gap: 2, width: '100%', flexDirection: 'column' }}>
                                <Alert
                                    sx={{ alignItems: 'flex-start' }}
                                    startDecorator={<ReportIcon/>}
                                    variant="soft"
                                    color="danger"
                                    endDecorator={
                                        <IconButton variant="soft" color="danger">
                                        <CloseRoundedIcon />
                                        </IconButton>
                                    }
                                    >
                                    <div>
                                        <div>Error</div>
                                        <Typography level="body-sm" color="danger">
                                            {error}
                                        </Typography>
                                    </div>
                                </Alert>
                            </Box>
                        }
                    </Box>
                    <Box component="footer" sx={{ py: 3 }}>
                        <Typography level="body-xs" textAlign="center">
                            © Exam Eve {new Date().getFullYear()}
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Box
                sx={{
                    height: '100%',
                    position: 'fixed',
                    right: 0,
                    top: 0,
                    bottom: 0,
                    left: 560,
                    transition: 'background-image var(--Transition-duration), left var(--Transition-duration) !important',
                    transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
                    backgroundColor: 'background.level1',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center center',
                    backgroundRepeat: 'no-repeat',
                    backgroundImage: 'url(https://images.unsplash.com/photo-1527181152855-fc03fc7949c8?auto=format&w=1000&dpr=2)'
                }}
            />
        </Box>
    );    
}