import React from 'react';
import Box from '@mui/joy/Box';
import Stack from '@mui/joy/Stack';
import Sheet from '@mui/joy/Sheet';
import Typography from '@mui/joy/Typography';

export default function ChatBubble({
    content,
    variant,
    sender,
}) {
    const isSent = variant === 'sent';
    return (
        <Box sx={{width: '100%', display: 'flex', alignItems: isSent ? 'flex-end' : 'flex-start', justifyContent: isSent ? 'flex-end' : 'flex-start' }}>
            <Box sx={{ maxWidth: '60%', minWidth: 'auto' }}>
                <Typography level="body-xs">
                    {sender === 'You' ? sender : sender.name}
                </Typography>
                <Box
                    sx={{ position: 'relative' }}
                >
                    <Sheet 
                        color='primary'
                        variant={isSent ? 'solid' : 'soft'}
                        sx={{ borderRadius: '10px' }}
                    >
                        <Typography
                            level="body-sm"
                            sx={{
                                color: isSent
                                    ? 'var(--joy-palette-common-white)'
                                    : 'black',
                                m: 1,
                                p: 1 // Add padding to create space between text and edges
                            }}
                        >
                            {content}
                        </Typography>
                    </Sheet>
                </Box>
            </Box>
        </Box>
    );
}
