import React, { useEffect, useState } from 'react';
import { Button, Typography, Box, Stack } from '@mui/joy';
import { db, auth } from '../firebase';
import { addDoc, collection, query, where, onSnapshot, orderBy, limit, updateDoc, doc } from 'firebase/firestore';
import LinearProgress from '@mui/joy/LinearProgress';
import { useParams } from "react-router-dom";
import ChatBubble from './ChatBubble';
import { getFunctions, httpsCallable } from "firebase/functions";
import Textarea from '@mui/joy/Textarea';

const functions = getFunctions();
const conversationCall = httpsCallable(functions, "conversation");

export default function ConversationQuestion({ courseId, question, number, nik }) {
    const params = useParams();
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(false); 
    const [chatText, setChatText] = useState([]);
    const [text, setText] = useState('');
    const [conversationId, setConversationId] = useState(null);

    const newChatMessage = (tempChat) => {
        setLoading(true);

        if(conversationId === null){
            addDoc(collection(db, "conversations"), {
                courseId: courseId,
                conversation: tempChat,
                questionNumber: parseInt(number),
                createdAt: new Date(),
                user: user.uid,
            }).then((docRef) => {
                setConversationId(docRef.id);
            }).catch((error) => {
                console.log(error);
            });
        } else {
            updateDoc(doc(db, "conversations", conversationId), {
                conversation: tempChat,
            }).catch((error) => {
                console.log(error);
                setLoading(false);
            });
        }
        setLoading(false);
    }

    useEffect(() => {
        if(conversationId !== null){
            console.log("conversationId", conversationId)
            const unsubscribe = onSnapshot(doc(db, "conversations", conversationId), (doc) => {
                console.log("Current data: ", doc.data());
                setChatText(doc.data().conversation);
            });
            
            return () => {
                unsubscribe();
            }
        }
    }, [conversationId]);

    const newPage = () => {
        // let unsubscribe_responses;
        const unsubscribe = auth.onAuthStateChanged((authUser) => {
            if (authUser) {
                setUser(authUser);
            } else {
                setUser(null);
            }
        });
        return () => {
            unsubscribe();
        };
    }

    useState(() => {
        newPage();
    }, [params.page]);

    useState(() => {
        newPage();
    }, []);

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            width="60%"
            margin="auto"
            height="100%"
        >
            <Box sx={{ mt: 5, height:'100%', display: 'flex', alignContent:'space-around', justifyContent:'space-around', flexDirection:"column", width:"100%" }}>
                <Typography variant="h6">
                    Task {number}
                </Typography>
                <Typography variant="h3">
                    {question}
                </Typography>
                <Stack sx={{ minHeight: '80%', overflow: 'auto' }}>
                    {
                        chatText.map((chat, index) => (
                            <ChatBubble
                                key={index}
                                content={chat}
                                variant={index % 2 === 0 ? "sent" : "received"}
                                sender={index % 2 === 0 ? "You" : "John"}
                            />
                        ))
                    }
                </Stack>
                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "flex-end", position: "fixed", bottom: 0, left: 0, right: 0, mb: 3, width:'100%' }}>
                    <Box sx={{ width: '60%' , mb: 8}}>
                        {
                            loading || chatText.length % 2 !== 0 &&
                            <LinearProgress />
                        }
                        <Textarea
                            placeholder="Type something here…"
                            minRows={3}
                            value={text}
                            onChange={(e) => setText(e.target.value)}
                            disabled={chatText.length % 2 !== 0}
                            endDecorator={
                                <Box
                                    sx={{
                                    display: 'flex',
                                    gap: 'var(--Textarea-paddingBlock)',
                                    pt: 'var(--Textarea-paddingBlock)',
                                    borderTop: '1px solid',
                                    borderColor: 'divider',
                                    flex: 'auto',
                                    }}
                                >
                                    <Button
                                        sx={{ ml: 'auto' }}
                                        disabled={chatText.length % 2 !== 0}
                                        onClick={() => {
                                            const tempChat = chatText
                                            tempChat.push(text);

                                            setChatText(tempChat);
                                            setText('');
                                            newChatMessage(tempChat);
                                        }}
                                    >
                                        Send
                                    </Button>
                                </Box>
                            }
                            sx={{
                                minWidth: 300,
                                fontStyle: 'initial',
                            }}
                        />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}
