import * as React from 'react';

import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Divider from '@mui/joy/Divider';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import Card from '@mui/joy/Card';
import CardActions from '@mui/joy/CardActions';
import CardOverflow from '@mui/joy/CardOverflow';
import Chip from '@mui/joy/Chip';
import Checkbox from '@mui/joy/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

// Other compoenents
import ImageMaterial from './ImageMaterial';

function MultipleChoiceQuestion({ courseId, question, options, answers, question_images, number }) {
  const [userWantsToCheck, setUserWantsToCheck] = React.useState(false);
  const [isCorrect, setIsCorrect] = React.useState("Check");
  const [checkedDict, setCheckedDict] = React.useState([]);
  const [checkColors, setCheckColors] = React.useState([]);

  const handleCheck = () => {
    setUserWantsToCheck(true);

    if(JSON.stringify(checkedDict.sort()) === JSON.stringify(answers.sort())){
      setIsCorrect("Correct!");
    }
    else{
      setIsCorrect("Incorrect!");
      setCheckedDict([...checkedDict, ...answers]);
    }

    //Fill the incorrect answers with red
    let temp = [];
    for(let i = 0; i < options.length; i++){
      if(answers.includes(i)){
        temp.push("success");
      }
      else{
        temp.push("danger");
      }
    }
    setCheckColors(temp);
  }
  
  const handleSelectCheck = (index) => {
    if (userWantsToCheck) {
      return;
    }

    if(checkedDict.includes(index)){
      setCheckedDict(checkedDict.filter((item) => item !== index));
    }
    else{
      if(checkedDict.length + 1 > answers.length){
        return;
      }
      setCheckedDict([...checkedDict, index]);
    }
  }

  React.useEffect(() => {
    let temp = [];
    for(let i = 0; i < options.length; i++){
      temp.push("primary");
    }
    setCheckColors(temp);
  }, []);

  return (
    <Box
        sx={{
          flex: 1,
          width: '100%',
        }}
      >  
        <Stack
          spacing={4}
          sx={{
            display: 'flex',
            maxWidth: '1200px',
            mx: 'auto',
            px: {
              xs: 2,
              md: 6,
            },
            py: {
              xs: 2,
              md: 3,
            },
          }}
        >
          <Card>
            <Box sx={{ mb: 1 }}>
              <Typography level="title-sg" sx={{ fontWeight: 'bold' }}>Question {number}</Typography>
              <Typography level="body-md">
                <span dangerouslySetInnerHTML={{ __html: question }} />
              </Typography>
              {
                question_images !== undefined && 
                // loop through the question_images array and display each image
                question_images.map((image, index) => (
                  <ImageMaterial key={index} courseId={courseId} image_name={image} />
                ))
              }
            </Box>
            <Divider />
            <Stack spacing={2} sx={{ flexGrow: 1 }}>
              {
                options.map((option, index) => (
                  <FormControlLabel 
                    sx={{ my: 1 }}
                    key={index}
                    control={<Checkbox checked={checkedDict.includes(index)} color={checkColors[index]} onChange={() => {handleSelectCheck(index)}} label={option} key={index}/>}/>
                ))

              }
            </Stack>
            <CardOverflow sx={{ borderTop: '1px solid', borderColor: 'divider' }}>
              <CardActions sx={{ alignSelf: 'flex-end', pt: 2 }}>
                {
                  userWantsToCheck &&
                  <>
                    {
                      isCorrect === "Correct!" ? 
                        <Chip
                          color="success"
                          size="lg"
                          variant="solid"
                        >
                          {isCorrect}
                        </Chip>
                      :
                        <Chip
                          color="danger"
                          size="lg"
                          variant="solid"
                        >
                          {isCorrect}
                        </Chip>
                    }
                  </>
                }
                <Button size="sm" variant="solid" onClick={handleCheck}>
                  Check
                </Button>
              </CardActions>
            </CardOverflow>
          </Card>

        </Stack>
      </Box>
  );

}
export default MultipleChoiceQuestion;