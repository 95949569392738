//import questionBox
import { useEffect, useState } from 'react';

import CourseBox from '../components/CourseBox';
import Box from '@mui/material/Box';

//import everything that will enable me to read collections in firestore
import { db } from '../firebase';
import { getDocs, collection, query, where, doc, getDoc } from "@firebase/firestore"

import { auth } from '../firebase';
import { useNavigate } from 'react-router-dom';
import { signOut } from "firebase/auth";
import CourseBoxSkeleton from '../components/CourseBoxSkeleton';

import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import { Typography } from '@mui/joy';

function Courses() {
  const [user, setUser] = useState(null); //user is an object with keys [displayName, email, emailVerified, photoURL, uid
  const [courses, setCourses] = useState([]); //courses is an array of objects, each object is a course
  const [userCourses, setUserCourses] = useState({}); //userCourses is an array of objects, each object is a course
  const [selectedOption, setSelectedOption] = useState('GCP');
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const handleOptionChange = (event, newValue) => {
    setSelectedOption(newValue);
  };

  useEffect(() => {
    getCourses();
  }, [selectedOption]);

  //read the courses collection from firestore
  const getCourses = async () => {
    let finalData = [];
    let ref;
    
    if (user !== null && user.uid === "PzVgfUgkFagHdJVKC6hOA7qwPEU2") {
      ref = query(collection(db, "courses"), where("titletag", "==", selectedOption));
    } else {
      ref = query(collection(db, "courses"), where("titletag", "==", selectedOption), where("available", "==", true));
    }
    
    const querySnapshot = await getDocs(ref);
    querySnapshot.forEach((doc) => {
      finalData.push({ courseId: doc.id, ...doc.data() });
    });
    
    console.log(finalData)
    setCourses(finalData);
    setLoading(false);
  }

  const getUserDocument = () => {
    auth.onAuthStateChanged(async (user) => {
      setUser(user);
      if(user !== null && user.displayName === null){
        navigate("/verify")
      }
      if(user !== null && user.emailVerified === false){
        signOut(auth).then((event) => {
          // Sign-out successful.
          // go to page notverified
          navigate("/notverified")
        })
      }
      if(user !== null){
        console.log(user)
        setUser(user);
        const docSnap = await getDoc(doc(db, "users", user.uid));
        if (docSnap.exists()) {
          setUserCourses(docSnap.data()["accessibleCourses"]);
          console.log(docSnap.data()["accessibleCourses"]);
        }
      }
    });
  }

  useEffect(() => {
    getUserDocument();
    getCourses();
  }, []);

  return (
    <div style={{marginTop: 100}}>
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', flexWrap: 'wrap', width: '80%', textAlign: 'center', mx: 'auto', mb: 5 }}>
        <Typography level="h1" sx={{ fontWeight: 'bold' }}>Exams</Typography>
        <Select
          color="primary"
          disabled={false}
          placeholder="Choose one…"
          size="lg"
          variant="outlined"
          sx={{ width: 300, flexShrink: 1 }}
          defaultValue="GCP"
          onChange={handleOptionChange}
        >
          <Option value="GCP">Google (GCP)</Option>
          <Option value="AWS">Amazon (AWS)</Option>
          <Option value="MSC">Microsoft</Option>
          {/* <Option value="GRE">Graduate Record Exam (GRE)</Option> */}
          {/* <Option value="ENG">English</Option> */}
        </Select>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 5, flexWrap: 'wrap', mb:5, minWidth: 400 }}>
        {
          loading ?
            Array(20).map((_, index) => {
              <CourseBoxSkeleton/>
            })
          :
            courses.map((course, index) => (
              <CourseBox key={index} 
                loading={false}
                title={course.title} 
                courseId={course.courseId} 
                question_count={course.question_count}
                description={course.description} 
                price={course.price}
                number={course.number}
                titletag={course.titletag}
                colortype={course.colortype}
                colornumber={course.colornumber}
                courseAvailable={true}
                userHasCourse={userCourses[course.courseId] !== undefined || userCourses["all"] !== undefined}
                user={user}
                url={course.payment_link}
              />
            ))
        }
      </Box>
    </div>
  );
}

export default Courses;