import { Typography } from '@material-ui/core';

export default function TermsAndConditions() {
    return (
        <div style={{display: 'flex', flexDirection: 'column',justifyContent: 'center', width: '60%', margin: '0 auto'}}>
            <Typography variant="h6" gutterBottom>Effective Date: 2023-08-01</Typography>

            <Typography variant="body1" gutterBottom>
                At Exam Eve, Inc. ("Exam Eve," "we," "us," or "our"), we are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy explains how we collect, use, and safeguard your information when you use our platform related products and services (the "Service").
            </Typography>

            <Typography variant="h6" gutterBottom>1. Information We Collect</Typography>

            <Typography variant="body1" gutterBottom>
                We may collect the following types of information when you use our Service:
            </Typography>

            <Typography variant="body1" gutterBottom>
                <strong>1.1 Personal Information:</strong> This may include your name, email address, and other contact information provided during registration or when you contact us.
            </Typography>

            <Typography variant="body1" gutterBottom>
                <strong>1.2 Usage Data:</strong> We collect information about your interactions with the Service, such as IP addresses, browser type, operating system, and pages visited.
            </Typography>

            <Typography variant="h6" gutterBottom>2. How We Use Your Information</Typography>

            <Typography variant="body1" gutterBottom>
                We use the information we collect for the following purposes:
            </Typography>

            <Typography variant="body1" gutterBottom>
                <strong>2.1 Providing the Service:</strong> We use your information to provide and improve our Service, including data scraping and delivery.
            </Typography>

            <Typography variant="body1" gutterBottom>
                <strong>2.2 Communication:</strong> We may use your contact information to respond to your inquiries, send updates about the Service, and provide customer support.
            </Typography>

            <Typography variant="body1" gutterBottom>
                <strong>2.3 Legal Compliance:</strong> We may use your information as required by applicable laws, regulations, or to respond to legal requests.
            </Typography>

            <Typography variant="body1" gutterBottom>
                <strong>2.4 Analytics:</strong> We may use data to analyze and improve the Service's performance and user experience.
            </Typography>

            <Typography variant="h6" gutterBottom>3. Data Sharing</Typography>

            <Typography variant="body1" gutterBottom>
                We do not share your personal information with third parties for their marketing purposes. However, we may share your information under the following circumstances:
            </Typography>

            <Typography variant="body1" gutterBottom>
                <strong>3.1 Service Providers:</strong> We may use third-party service providers to help us deliver the Service, such as hosting, data storage, and customer support.
            </Typography>

            <Typography variant="body1" gutterBottom>
                <strong>3.2 Legal Requirements:</strong> We may disclose your information to comply with applicable laws, regulations, or in response to legal requests.
            </Typography>

            <Typography variant="h6" gutterBottom>4. Data Security</Typography>

            <Typography variant="body1" gutterBottom>
                We take reasonable steps to protect your information. While we strive to maintain the security of your data, we cannot guarantee that data transmission over the internet is completely secure.
            </Typography>

            <Typography variant="h6" gutterBottom>5. Your Choices and Rights</Typography>

            <Typography variant="body1" gutterBottom>
                You may have the following choices and rights regarding your information:
            </Typography>

            <Typography variant="body1" gutterBottom>
                <strong>5.1 Access and Update:</strong> You can access and update your personal information by logging into your account.
            </Typography>

            <Typography variant="body1" gutterBottom>
                <strong>5.2 Delete Your Data:</strong> You may request to delete your personal data, subject to legal and contractual obligations.
            </Typography>

            <Typography variant="body1" gutterBottom>
                <strong>5.3 Opt-Out:</strong> You can opt out of receiving non-essential communications from us.
            </Typography>

            <Typography variant="h6" gutterBottom>6. Changes to this Privacy Policy</Typography>

            <Typography variant="body1" gutterBottom>
                We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. Any changes will be posted on our website, and the effective date will be updated.
            </Typography>

            <Typography variant="h6" gutterBottom>7. Contact Us</Typography>

            <Typography variant="body1" gutterBottom>
                If you have any questions or concerns about our Privacy Policy or how we handle your information, please contact us at malakarnikheil@gmail.com.
            </Typography>

            <Typography variant="body1" gutterBottom>
                By using the Exam Eve Service, you agree to the practices outlined in this Privacy Policy.
            </Typography>

            <Typography variant="body1" gutterBottom>
                Last Updated: 2023-08-01
            </Typography>
        </div>
    )
}