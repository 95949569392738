import { useState } from 'react';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import FormControl from '@mui/joy/FormControl';
import FormLabel, { formLabelClasses } from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Typography from '@mui/joy/Typography';
import ReportIcon from '@mui/icons-material/Report';
import Alert from '@mui/joy/Alert';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import IconButton from '@mui/joy/IconButton';
import GoogleIcon from '../elements/GoogleIcon';

import { useNavigate } from 'react-router-dom';

import { auth } from '../firebase';
import { sendPasswordResetEmail } from "firebase/auth";

function Signin() {
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const handleResetPassword = async (email) => {
        sendPasswordResetEmail(auth, email)
        .then(() => {
            console.log("password reset")
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            if(errorMessage === "Firebase: Error (auth/user-not-found)."){
                setError("User not found inside Exam Eve")
            }else{
            setError("Unknown Error please try again!");
            }
        });

    }
    
    return (
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Box
                sx={(theme) => ({
                    height: '100%',
                    position: 'fixed',
                    right: 'clamp(0px, (100vw - var(--Collapsed-breakpoint)) * 999, 100vw - var(--Cover-width))',
                    top: 0,
                    bottom: 0,
                    left: 0,
                    zIndex: 1,
                    display: 'flex',
                    justifyContent: 'flex-end',
                    backdropFilter: 'blur(4px)',
                    backgroundColor: 'white'
                })}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        minHeight: '100dvh',
                        width:
                            'clamp(var(--Form-maxWidth), (var(--Collapsed-breakpoint) - 100vw) * 999, 100%)',
                        maxWidth: '100%',
                        px: 10,
                    }}
                >
                    <Box
                        component="header"
                        sx={{
                            py: 3,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Typography
                            fontWeight="lg"
                            startDecorator={
                                <Box
                                    component="span"
                                    sx={{
                                        width: 24,
                                        height: 24,
                                        background: (theme) =>
                                            `linear-gradient(45deg, ${theme.vars.palette.primary.solidBg}, ${theme.vars.palette.primary.solidBg} 30%, ${theme.vars.palette.primary.softBg})`,
                                        borderRadius: '50%',
                                        boxShadow: (theme) => theme.shadow.md,
                                        '--joy-shadowChannel': (theme) =>
                                            theme.vars.palette.primary.mainChannel,
                                    }}
                                />
                            }
                        >
                            Logo
                        </Typography>
                    </Box>
                    <Box
                        component="main"
                        sx={{
                            my: 'auto',
                            py: 2,
                            pb: 5,
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 2,
                            width: 400,
                            maxWidth: '100%',
                            mx: 'auto',
                            borderRadius: 'sm',
                            '& form': {
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 2,
                            },
                            [`& .${formLabelClasses.asterisk}`]: {
                                visibility: 'hidden',
                            },
                        }}
                    >
                        <div>
                            <Typography component="h1" fontSize="xl2" fontWeight="lg">
                                Reset Your Password
                            </Typography>
                            <Typography level="body-sm" sx={{ my: 1, mb: 3 }}>
                                Enter your Email below!
                            </Typography>
                        </div>
                        <form
                            onSubmit={(event) => {
                                event.preventDefault();
                                const formElements = event.currentTarget.elements;
                                handleResetPassword(formElements.email.value)
                            }}
                        >
                            <FormControl required>
                                <FormLabel>Email</FormLabel>
                                <Input type="email" name="email" />
                            </FormControl>
                            <Button type="submit" fullWidth>
                                Reset Password
                            </Button>
                        </form>
                        {
                            error != null && 
                            <Box sx={{ display: 'flex', gap: 2, width: '100%', flexDirection: 'column' }}>
                                <Alert
                                    sx={{ alignItems: 'flex-start' }}
                                    startDecorator={<ReportIcon/>}
                                    variant="soft"
                                    color="danger"
                                    endDecorator={
                                        <IconButton variant="soft" color="danger">
                                        <CloseRoundedIcon />
                                        </IconButton>
                                    }
                                    >
                                    <div>
                                        <div>Error</div>
                                        <Typography level="body-sm" color="danger">
                                            {error}
                                        </Typography>
                                    </div>
                                </Alert>
                            </Box>
                        }
                    </Box>
                    <Box component="footer" sx={{ py: 3 }}>
                        <Typography level="body-xs" textAlign="center">
                            ©Exam Eve {new Date().getFullYear()}
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Box
                sx={{
                    height: '100%',
                    position: 'fixed',
                    right: 0,
                    top: 0,
                    bottom: 0,
                    left: 560,
                    transition: 'background-image var(--Transition-duration), left var(--Transition-duration) !important',
                    transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
                    backgroundColor: 'background.level1',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center center',
                    backgroundRepeat: 'no-repeat',
                    backgroundImage: 'url(https://images.unsplash.com/photo-1527181152855-fc03fc7949c8?auto=format&w=1000&dpr=2)'
                }}
            />
        </Box>
    );        
}

export default Signin;

