import { useEffect, useState } from 'react';
import Table from '@mui/joy/Table';
import Sheet from '@mui/joy/Sheet';
import Typography from '@mui/joy/Typography';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Button from '@mui/joy/Button';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import TableContainer from '@mui/material/TableContainer';
import LinearProgress from '@mui/material/LinearProgress';
import { useNavigate } from 'react-router-dom';

import { getFunctions, httpsCallable } from "firebase/functions";
import { auth } from "../firebase";
import { onAuthStateChanged } from 'firebase/auth';


const functions = getFunctions();
const redeemCode = httpsCallable(functions, "redeem_code");

export default function Redeem() {
    const navigate = useNavigate();
    const [user, setUser] = useState(null); // [user, setUser
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);

    function redeem(code, password){    
        setLoading(true)    
        
        redeemCode({code: code, password: password, uid:user.uid}).then((result) => {
            // if the result is {message:"Success"} then navigate to /
            console.log(result)
            if(result.data.message === "Success"){
                // navigate to /
                navigate("/")
                console.log("success")
            }else{
                setError(true)
            }
            setLoading(false)
        }
        ).catch((error) => {
            setError(true)
            console.log(error)
        })
    }

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                setUser(user);
            } else {
                navigate("/login")
            }
        });
    }, []);

    return(
        <Box spacing={2} sx={{ display: 'flex', flexDirection:'column', px:10 }}>
            <Box sx={{ display: 'flex', flexDirection:'row', gap: 10, justifyContent:'space-around' }}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                    <Sheet
                        sx={{
                            minWidth:300,
                            width: 300,
                            mx: 'auto', // margin left & right
                            my: 4, // margin top & bottom
                            py: 3, // padding top & bottom
                            px: 2, // padding left & right
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 2,
                            borderRadius: 'sm',
                            boxShadow: 'md',
                        }}
                        variant="outlined"
                        >
                        <div>
                            <Typography level="h4" component="h1">
                            <b>Redeem Code</b>
                            </Typography>
                            <Typography level="body-sm">Enter your redeem code and password</Typography>
                        </div>
                        <form
                            onSubmit={(event) => {
                                event.preventDefault();
                                const formElements = event.currentTarget.elements;
                                redeem(formElements.redeemcode.value, formElements.password.value)
                            }}
                        >
                            <FormControl>
                                <FormLabel>Redeem Code</FormLabel>
                                <Input
                                    required
                                    // html input attribute
                                    name="redeemcode"
                                    type="text"
                                    placeholder="ABCDEF123123123"
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Password</FormLabel>
                                <Input
                                    required
                                    // html input attribute
                                    name="password"
                                    type="text"
                                    placeholder="password"
                                />
                            </FormControl>
                            {
                                loading &&
                                <LinearProgress sx={{mt: 3, mb: 2}}/>
                            }
                            <Button type="submit" sx={{ mt: 1, width: '100%' }}>Redeem</Button>
                            {
                                error &&
                                <Alert severity="error">Invalid Code or Password!</Alert>
                            }
                        </form>
                    </Sheet>
                </Box>
                <Box>
                    <Typography level="h1" sx={{
                        mx: 'auto', // margin left & right
                        my: 4, // margin top & bottom
                        px: 2, // padding left & right
                    }}>Available Courses</Typography>
                    <TableContainer sx={{ maxHeight: 440 }}>
                        <Table
                            aria-label="basic table"
                            size="lg"
                            variant="soft"
                            stripe="even"
                            sx={{
                                mx: 'auto', // margin left & right
                                my: 4, // margin top & bottom
                                py: 3, // padding top & bottom
                                px: 2, // padding left & right
                            }}
                        >
                            <thead style={{ position: 'sticky', top: 0 }}>
                                <tr>
                                    <th>Course Name</th>
                                </tr>
                            </thead>
                            <tbody>
                                    <tr>
                                        <td>AWS-Advanced-Networking</td>
                                    </tr>
                                    <tr>
                                        <td>AWS-Alexa-Skill-Builder</td>
                                    </tr>
                                    <tr>
                                        <td>AWS-Big-Data</td>
                                    </tr>
                                    <tr>
                                        <td>AWS-Cloud-Practitioner</td>
                                    </tr>
                                    <tr>
                                        <td>AWS-Data-Analytics</td>
                                    </tr>
                                    <tr>
                                        <td>AWS-Database</td>
                                    </tr>
                                    <tr>
                                        <td>AWS-Developer</td>
                                    </tr>
                                    <tr>
                                        <td>AWS-Devops-Engineer</td>
                                    </tr>
                                    <tr>
                                        <td>AWS-Machine-Learning</td>
                                    </tr>
                                    <tr>
                                        <td>AWS-Sap-On-AWS</td>
                                    </tr>
                                    <tr>
                                        <td>AWS-Security</td>
                                    </tr>
                                    <tr>
                                        <td>AWS-Solutions-Architect</td>
                                    </tr>
                                    <tr>
                                        <td>AWS-Sysops-Administrator</td>
                                    </tr>
                                    <tr>
                                        <td>Google-Adwords-Fundamentals</td>
                                    </tr>
                                    <tr>
                                        <td>Google-Associate-Cloud-Engineer</td>
                                    </tr>
                                    <tr>
                                        <td>Google-Cloud-Digital-Leader</td>
                                    </tr>
                                    <tr>
                                        <td>Google-Google-Analytics</td>
                                    </tr>
                                    <tr>
                                        <td>Google-Gsuite</td>
                                    </tr>
                                    <tr>
                                        <td>Google-Individual-Qualification</td>
                                    </tr>
                                    <tr>
                                        <td>Google-Professional-Cloud-Architect</td>
                                    </tr>
                                    <tr>
                                        <td>Google-Professional-Cloud-Database-Engineer</td>
                                    </tr>
                                    <tr>
                                        <td>Google-Professional-Cloud-Developer</td>
                                    </tr>
                                    <tr>
                                        <td>Google-Professional-Cloud-Devops-Engineer</td>
                                    </tr>
                                    <tr>
                                        <td>Google-Professional-Cloud-Network-Engineer</td>
                                    </tr>
                                    <tr>
                                        <td>Google-Professional-Cloud-Security-Engineer</td>
                                    </tr>
                                    <tr>
                                        <td>Google-Professional-Collaboration-Engineer</td>
                                    </tr>
                                    <tr>
                                        <td>Google-Professional-Data-Engineer</td>
                                    </tr>
                                    <tr>
                                        <td>Google-Professional-Google-Workspace-Administrator</td>
                                    </tr>
                                    <tr>
                                        <td>Google-Professional-Machine-Learning-Engineer</td>
                                    </tr>
                                    <tr>
                                        <td>Google-Video-Advertising</td>
                                    </tr>
                                    <tr>
                                        <td>GRE-Reading</td>
                                    </tr>
                                </tbody>
                        </Table>
                    </TableContainer>
                </Box>
            </Box>
        </Box>
    )
}
