import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';

export default function Home() {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', height:'90%', justifyContent:'space-around' }}>
            <Box sx={{ flexDirection: 'column', height: '100%', width:'50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Typography variant="h7" sx={{ fontWeight: 'bold' }}>
                        Your Last-Stop for Exam Preparation
                    </Typography>
                    <Typography variant="h2" sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                        Ace Your Exams with <br/> Expert Guidance
                    </Typography>
                    <Typography variant="subtitle1" sx={{mt:2, width: '80%', textAlign: 'center' }} justifyContent="center">
                        Ace your exams with Exam Eve for unparalleled preparation and success.
                    </Typography>
                    <Button 
                        variant="solid"
                        component={Link}
                        to="/exams"
                        sx={{
                            mt: 2
                        }}
                    >
                        Explore Exams
                    </Button>
            </Box>
        </Box>
    );
}
