import { useNavigate } from 'react-router-dom';

import * as React from 'react';
import AspectRatio from '@mui/joy/AspectRatio';
import Button from '@mui/joy/Button';
import Card from '@mui/joy/Card';
import CardActions from '@mui/joy/CardActions';
import CardContent from '@mui/joy/CardContent';
import CardOverflow from '@mui/joy/CardOverflow';
import Typography from '@mui/joy/Typography';
import { red, pink, purple, deepPurple, indigo, blue, deepOrange, grey, blueGrey, orange, yellow } from '@mui/material/colors';


export default function CourseBox({title, description, courseId, question_count, price, titletag, colortype, colornumber, courseAvailable, userHasCourse, user, url}) {
    const MAX_TITLE_LENGTH = 100;
    const MAX_DESCRIPTION_LENGTH = 600;
    let trimmedTitle = title.length > MAX_TITLE_LENGTH ? title.substring(0, MAX_TITLE_LENGTH) + "..." : title;
    let trimmedDescription = description.length > MAX_DESCRIPTION_LENGTH ? description.substring(0, MAX_DESCRIPTION_LENGTH) + "..." : description;
    
    const navigate = useNavigate();
    
    const handleTakeCourse = () => {
        console.log(user)
        if(["GRE-Reading"].includes(courseId)){
            navigate("/passage/" + courseId + "/1", {
                courseId: courseId,
                allQuestions: {}
            })
        } else if(["GRE-Analyze-An-Argument"].includes(courseId)){
            navigate("/essay/" + courseId + "/1", {
                courseId: courseId,
                allQuestions: {}
            })
        } else if(["English-Conversations"].includes(courseId)){
            navigate("/conversation/" + courseId + "/1", {
                courseId: courseId,
                allQuestions: {}
            })
        } else {
            navigate("/" + courseId + "/1", {
                courseId: courseId,
                allQuestions: {}
            })
        }
    };

    const courseColor = colortype === 'red' ? red[colornumber] :
                        colortype === 'pink' ? pink[colornumber] :
                        colortype === 'purple' ? purple[colornumber] :
                        colortype === 'deepPurple' ? deepPurple[colornumber] :
                        colortype === 'indigo' ? indigo[colornumber] :
                        colortype === 'blue' ? blue[colornumber] :
                        colortype === 'deepOrange' ? deepOrange[colornumber] :
                        colortype === 'orange' ? orange[colornumber] :
                        colortype === 'grey' ? grey[colornumber] :
                        colortype === 'blueGrey' ? blueGrey[colornumber] :
                        colortype === 'yellow' ? yellow[colornumber] :
                        blueGrey[colornumber];
    
    return (
        <Card
            data-resizable
            sx={{
                textAlign: 'center',
                alignItems: 'center',
                width: 360,
                height: 550,
                // to make the demo resizable
                overflow: 'auto',
                resize: 'horizontal',
                '--icon-size': '100px',
            }}
        >
            <CardOverflow variant="solid" style={{backgroundColor: courseColor}}>
                <AspectRatio
                    variant="outlined"
                    ratio="1"
                    sx={{
                        m: 'auto',
                        transform: 'translateY(50%)',
                        borderRadius: '50%',
                        width: 'var(--icon-size)',
                        boxShadow: 'sm',
                        bgcolor: 'background.surface',
                        position: 'relative',
                    }}
                >
                <div>
                    <Typography level="h2" variant="solid" style={{backgroundColor: courseColor}}>
                        {titletag}
                    </Typography>
                </div>
                </AspectRatio>
            </CardOverflow>
            <Typography level="title-lg" sx={{ mt: 'calc(var(--icon-size) / 2)' }}>
                {trimmedTitle}
            </Typography>
            <Typography level="h5">
                {question_count} Questions
            </Typography>
            <CardContent sx={{ maxWidth: '45ch' }}>
                {trimmedDescription}
            </CardContent>
            <CardActions
                orientation="vertical"
                buttonFlex={1}
                sx={{
                '--Button-radius': '40px',
                width: 'clamp(min(100%, 160px), 50%, min(100%, 200px))',
                }}
            >
                {
                    !userHasCourse ?
                    <>
                        <Typography level="title-lg" sx={{ color: '#d4d4d4', fontSize: '15px' }}>
                            2 weeks
                        </Typography>
                        <Button variant="solid" style={{backgroundColor: courseColor}} onClick={() => {
                            if(!user){
                                navigate("/signup");
                                return;
                            }
                            window.open(url + "?utm_source=" + user.uid + "&client_reference_id=" + user.uid);
                        }}>
                            $ {price}  |  Purchase
                        </Button>
                    </>
                    :
                    <Button variant="solid" style={{backgroundColor: courseColor}} onClick={handleTakeCourse}>
                        Go to Course
                    </Button>
                }
            </CardActions>
        </Card>
    );
}