import * as React from 'react';

//Material UI components
import Box from '@mui/joy/Box';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import Card from '@mui/joy/Card';

function PassageBox({ passageId, passage, number }) {

  return (
    <Box
        sx={{
          flex: 1,
        }}
      >  
        <Stack
          spacing={4}
          sx={{
            display: 'flex',
            maxWidth: '1200px',
            // mx: 'auto',
            px: {
              xs: 2,
              md: 6,
            },
            py: {
              xs: 2,
              md: 3,
            },
          }}
        >
          <Card >
            <Box sx={{ mb: 1 }}>
              <Typography level="title-sg" sx={{ fontWeight: 'bold' }}>Passage {number}</Typography>
              <Typography level="body-md">
                <span dangerouslySetInnerHTML={{ __html: passage }} />
              </Typography>
            </Box>
          </Card>
        </Stack>
      </Box>
  );

}
export default PassageBox;