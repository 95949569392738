import { useState, useEffect } from "react";

import { db } from '../firebase';
import { getDocs, collection, startAfter, limit, query, orderBy } from "@firebase/firestore"

import { useParams, useLocation, useNavigate } from "react-router-dom";

import Stack from '@mui/material/Stack';
import Button from '@mui/joy/Button';
import Box from '@mui/material/Box';

//Question Types
import ConversationQuestion from "../components/ConversationQuestion";


import Input from '@mui/joy/Input';
import FormControl from '@mui/joy/FormControl';


function Conversations(props){
    const navigate = useNavigate();
    const params = useParams();
    const [allQuestions, setAllQuestions] = useState({}); 
    const [pageInput, setPageInput] = useState(params.page);
    const location = useLocation();

    useEffect(() => {
        const url = window.location.pathname;
        setPageInput(url.substring(url.lastIndexOf('/') + 1))

        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });

        const getQuestions = async () => {
            let tempAllQuestions = {}

            if(location.hasOwnProperty("state") && location.state!== null){
                tempAllQuestions = location.state.allQuestions
            }
            
            if(!tempAllQuestions.hasOwnProperty(params.page)){
                let finalData = []

                const ref = query(collection(db, "courses/"+params.courseId+"/questions"), orderBy("number"), startAfter((parseInt(params.page) - 1)), limit(1));
                const querySnapshot = await getDocs(ref);

                await querySnapshot.docs.map((doc) => {
                    finalData.push({courseId: doc.id, ...doc.data()})
                });

                tempAllQuestions[params.page] = finalData
                setAllQuestions(tempAllQuestions)
                console.log(tempAllQuestions)
            }
            else
            {
                setAllQuestions(tempAllQuestions)
            }
        };
        getQuestions();
    }, [params.page]);

    const handleNext = () => {
        navigate("/essay/" + params.courseId + "/" + String(parseInt(params.page)+1), {state:{
            courseId: params.courseId,
            allQuestions: allQuestions
        }})
        window.location.reload();
    }

    const handlePrevious = () => {
        navigate("/essay/" + params.courseId + "/" + String(parseInt(params.page)-1), {state: {
            courseId: params.courseId,
            allQuestions: allQuestions
        }})
        window.location.reload();
    }

    return(
        <div style={{ display: "flex", flexDirection: "column", gap: "10px"}}>
            <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={2}
            >
                {
                    allQuestions.hasOwnProperty(params.page) &&
                    <ConversationQuestion
                        courseId={params.courseId}
                        question={allQuestions[params.page][0].question}
                        number={params.page}
                        nik={allQuestions[params.page][0].nik}
                    />
                }
            </Stack>
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "flex-end", position: "fixed", bottom: 0, left: 0, right: 0, mb: 3 }}>
                <Button variant="contained" color="primary" onClick={handlePrevious} disabled={parseInt(params.page)===1}>Previous</Button>
                <FormControl sx={{mx: 3}}>
                    <Input
                        sx={{ '--Input-decoratorChildHeight': '45px', width: 120, textAlign: "center" }}
                        type="text"
                        value={pageInput}
                        required
                        onChange={(event) =>
                            setPageInput(event.target.value)
                        }
                        endDecorator={
                            <Button
                                variant="solid"
                                color="primary"
                                type="submit"
                                sx={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                                onClick={()=> {
                                    navigate("/" + params.courseId + "/" + pageInput, {
                                        courseId: params.courseId,
                                        questions: null
                                    })
                                }}
                            >
                            Go
                            </Button>
                        }
                    />
                </FormControl>
                <Button variant="contained" color="primary" onClick={handleNext}>Next</Button>
            </Box>
        </div>
    )
}

export default Conversations;