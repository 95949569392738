import * as React from 'react';

import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Divider from '@mui/joy/Divider';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import Card from '@mui/joy/Card';
import CardActions from '@mui/joy/CardActions';
import CardOverflow from '@mui/joy/CardOverflow';
import Chip from '@mui/joy/Chip';
import Grid from '@mui/material/Grid';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';

function FillInTheBlanksQuestions({ courseId, question, options, answers, question_images, number }) {
  const [userWantsToCheck, setUserWantsToCheck] = React.useState(false);
  const [isCorrect, setIsCorrect] = React.useState("Check");
  const [selectColor, setSelectColor] = React.useState(Array(options.length).fill("primary"));
  const [answerText, setAnswerText] = React.useState([]);
  const [altOptions, setAltOptions] = React.useState(options);

  const [selectedOption, setSelectedOption] = React.useState({});

  const handleCheck = () => {
    setUserWantsToCheck(true);

    // get the indexes of the selected options
    let selectedOptionIndexes = Object.values(selectedOption).map((option) => options.indexOf(option))
    console.log({selectedOptionIndexes, answers})
    if(JSON.stringify(selectedOptionIndexes) === JSON.stringify(answers)){
      setIsCorrect("Correct!");
    }
    else{
      setIsCorrect("Incorrect!");
      // get the answers that were supposed to be selected
      let correctAnswers = answers.map((answer) => options[answer])
      setAnswerText(correctAnswers)
    }

    //Fill the incorrect answers with red
    let temp = [];
    for(let i = 0; i < options.length; i++){
      if(answers.includes(i)){
        temp.push("success");
      }
      else{
        temp.push("danger");
      }
    }
  }

  React.useEffect(() => {
    let temp = [];
    console.log({ courseId, question, options, answers, question_images, number })
    for(let i = 0; i < options.length; i++){
      temp.push("primary");
    }
  }, []);

  return (
    <Box
        sx={{
          flex: 1,
          width: '100%',
        }}
      >  
        <Stack
          spacing={4}
          sx={{
            display: 'flex',
            maxWidth: '1200px',
            mx: 'auto',
            px: {
              xs: 2,
              md: 6,
            },
            py: {
              xs: 2,
              md: 3,
            },
          }}
        >
          <Card>
            <Box sx={{ mb: 1 }}>
              <Typography level="title-sg" sx={{ fontWeight: 'bold' }}>Question {number}</Typography>
              <Box style={{display: "flex", flexDirection: "row", flexWrap: "wrap", overfloWrap: 'break-word'}}>
                {question.map((item, index) => {
                  return (
                    <React.Fragment key={index}>
                      <Box sx={{ display: 'flex', alignItems: 'center', overfloWrap: 'break-word' }}>
                          {item}
                        {
                          index < question.length-1 && 
                          <Select
                            color={userWantsToCheck ? (selectedOption[index] === options[answers[index]] ? "success" : "danger") : "primary"}
                            placeholder="Choose one…"
                            size="sm"
                            variant={userWantsToCheck ? "solid" : "outlined"}
                            sx={{minWidth: 180, mx:1}}
                            onChange={(event, newValue) => {
                              let tempSelectedOption = selectedOption
                              tempSelectedOption[index] = newValue;
                              setSelectedOption(tempSelectedOption);
                              
                              let tempAltOptions = altOptions.filter((option, index) => !Object.values(selectedOption).includes(option))
                              setAltOptions(tempAltOptions)
                            }}
                          >
                            {
                              selectedOption[index] !== undefined &&
                              <Option value={selectedOption[index]}>
                                {selectedOption[index]}
                              </Option>
                            }
                            {
                              altOptions.map((option, index) => (
                                <Option key={index} value={option}>
                                  {option}
                                </Option>
                              ))
                            }
                          </Select>
                        }
                      </Box>
                    </React.Fragment>
                  );
                })}
              </Box>
            </Box>
            <Divider />
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              {
                options.map((option, index) => (
                  <Grid item xs={12} md={6} key={index}>
                    <Card>
                      <Typography level="body-sm">
                        {option}
                      </Typography>
                    </Card>
                  </Grid>
                ))
              }
            </Grid>
            <CardOverflow sx={{ borderTop: '1px solid', borderColor: 'divider' }}>
              <CardActions sx={{ alignSelf: 'flex-end', pt: 2 }}>
                {
                  answerText.length > 0 &&
                  <>
                    <Typography level="body-md">
                      Answers: 
                    </Typography>
                    {
                      answerText.map((answer, index) => (
                        <Chip color={answer === selectedOption[index] ? "success" : "danger"} key={index}>
                          {index+1}.{answer}
                        </Chip>
                      ))
                    }
                  </>
                }
                {
                  userWantsToCheck &&
                  <>
                    {
                      isCorrect === "Correct!" ? 
                        <Chip
                          color="success"
                          size="lg"
                          variant="solid"
                        >
                          {isCorrect}
                        </Chip>
                      :
                        <Chip
                          color="danger"
                          size="lg"
                          variant="solid"
                        >
                          {isCorrect}
                        </Chip>
                    }
                  </>
                }
                <Button size="sm" variant="solid" onClick={handleCheck}>
                  Check
                </Button>
              </CardActions>
            </CardOverflow>
          </Card>

        </Stack>
      </Box>
  );

}
export default FillInTheBlanksQuestions;