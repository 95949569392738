import { Typography } from '@material-ui/core';

export default function TermsAndConditions() {
    return (
        <div style={{display: 'flex', flexDirection: 'column',justifyContent: 'center', width: '60%', margin: '0 auto'}}>
            <Typography variant="h4" gutterBottom>
                Terms and Conditions of Service for Exam Eve, Inc.
            </Typography>

            <Typography variant="subtitle1" gutterBottom>
                Effective Date: October 1, 2023
            </Typography>

            <Typography variant="body1" gutterBottom>
                <strong>Acceptance of Terms</strong>
            </Typography>

            <Typography variant="body1" gutterBottom>
                Welcome to Exam Eve, Inc. ("Exam Eve," "we," "us," or "our"). These Terms and Conditions ("Terms") govern your use of Exam Eve's platform and educational services (collectively, the "Service"). By accessing or using our Service, you agree to comply with and be bound by these Terms. If you do not agree to these Terms, please do not use the Service.
            </Typography>

            <Typography variant="body1" gutterBottom>
                <strong>Description of the Service</strong>
            </Typography>

            <Typography variant="body1" gutterBottom>
                Exam Eve provides a platform that collects, organizes, and sells scraped data from the internet to companies for various purposes. This data may include, but is not limited to, text, images, documents, and other publicly accessible information.
            </Typography>

            <Typography variant="body1" gutterBottom>
                <strong>Use of the Service</strong>
            </Typography>

            <Typography variant="body1" gutterBottom>
                <strong>Eligibility</strong>
            </Typography>

            <Typography variant="body1" gutterBottom>
                By using the Service, you represent and warrant that you are at least 18 years of age and have the legal capacity to enter into these Terms. If you are using the Service on behalf of a company or organization, you represent that you have the authority to bind such entity to these Terms.
            </Typography>

            <Typography variant="body1" gutterBottom>
                <strong>Permitted Use</strong>
            </Typography>

            <Typography variant="body1" gutterBottom>
                You may use the Service only for lawful purposes, and you agree to comply with all applicable laws and regulations. You understand and acknowledge that Exam Eve does not own the data it provides, and you may only use the data in accordance with applicable laws and any additional restrictions or permissions associated with the data.
            </Typography>

            <Typography variant="body1" gutterBottom>
                <strong>Prohibited Activities</strong>
            </Typography>

            <Typography variant="body1" gutterBottom>
                You are expressly prohibited from engaging in the following activities when using the Service:
            </Typography>

            <Typography variant="body1" gutterBottom>
                a. Violating any applicable laws, regulations, or third-party rights.
            </Typography>

            <Typography variant="body1" gutterBottom>
                b. Unauthorized data scraping, data extraction, or data mining of the Service.
            </Typography>

            <Typography variant="body1" gutterBottom>
                c. Distributing, sharing, or selling scraped data from the Service to third parties.
            </Typography>

            <Typography variant="body1" gutterBottom>
                d. Modifying, adapting, reverse engineering, or attempting to gain unauthorized access to the Service or any part thereof.
            </Typography>

            <Typography variant="body1" gutterBottom>
                e. Engaging in any activity that disrupts or interferes with the proper functioning of the Service.
            </Typography>

            <Typography variant="body1" gutterBottom>
                <strong>Data Ownership</strong>
            </Typography>

            <Typography variant="body1" gutterBottom>
                You acknowledge and agree that Exam Eve does not own the questions, answers, or other data it provides. Exam Eve solely acts as an intermediary to collect and deliver publicly accessible data to users. Any data obtained through the Service is subject to the terms and conditions of the source from which it was scraped.
            </Typography>

            <Typography variant="body1" gutterBottom>
                <strong>Pricing and Payments</strong>
            </Typography>

            <Typography variant="body1" gutterBottom>
                Exam Eve's pricing for the use of the Service is subject to change at any time. You agree to pay the fees associated with your use of the Service in accordance with the payment terms provided. All fees are non-refundable.
            </Typography>

            <Typography variant="body1" gutterBottom>
                <strong>Intellectual Property</strong>
            </Typography>

            <Typography variant="body1" gutterBottom>
                The Exam Eve Service and all associated content, including but not limited to software, logos, trademarks, and text, are protected by intellectual property rights. You may not reproduce, distribute, or create derivative works of the Service without prior written permission from Exam Eve.
            </Typography>

            <Typography variant="body1" gutterBottom>
                <strong>Privacy Policy</strong>
            </Typography>

            <Typography variant="body1" gutterBottom>
                Your use of the Service is also governed by our Privacy Policy, which outlines how we collect, use, and protect your personal information. Please review the Privacy Policy to understand how we handle your data.
            </Typography>

            <Typography variant="body1" gutterBottom>
                <strong>Termination</strong>
            </Typography>

            <Typography variant="body1" gutterBottom>
                Exam Eve reserves the right to terminate your access to the Service at our discretion, with or without cause, at any time. Upon termination, you must cease using the Service and any data obtained through it.
            </Typography>

            <Typography variant="body1" gutterBottom>
                <strong>Disclaimer of Warranties</strong>
            </Typography>

            <Typography variant="body1" gutterBottom>
                THE SERVICE IS PROVIDED "AS IS" AND "AS AVAILABLE" WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. Exam Eve DISCLAIMS ALL WARRANTIES, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT.
            </Typography>

            <Typography variant="body1" gutterBottom>
                <strong>Limitation of Liability</strong>
            </Typography>

            <Typography variant="body1" gutterBottom>
                TO THE MAXIMUM EXTENT PERMITTED BY LAW, Exam Eve SHALL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, OR ANY LOSS OF PROFITS OR REVENUES, WHETHER INCURRED DIRECTLY OR INDIRECTLY.
            </Typography>

            <Typography variant="body1" gutterBottom>
                <strong>Use of AI and Ownership of AI-Generated Content</strong>
            </Typography>

            <Typography variant="body1" gutterBottom>
                You acknowledge that Exam Eve may employ artificial intelligence (AI) and machine learning technologies in the collection, organization, and delivery of data through the Service. AI may be used to automate data extraction, classification, or other processes related to the Service.
            </Typography>

            <Typography variant="body1" gutterBottom>
                <strong>Ownership of AI-Generated Content</strong>
            </Typography>

            <Typography variant="body1" gutterBottom>
                Exam Eve recognizes that certain content provided through the Service may be generated, in whole or in part, by AI technologies. You understand and agree that content generated by AI, including but not limited to summaries, analyses, recommendations, or any other derivative content, is not owned by Exam Eve. Exam Eve may hold licenses or rights to use AI-generated content, but such content is subject to the terms and conditions of the respective AI technology providers.

                You may use AI-generated content obtained through the Service in accordance with applicable laws and any additional restrictions or permissions associated with the content. Exam Eve does not grant ownership rights or exclusive use rights to AI-generated content.

                This clause clarifies that Exam Eve utilizes AI in its operations and makes it clear that content generated by AI is not owned by Exam Eve but is subject to the terms of the AI technology providers. Users are advised to use AI-generated content in compliance with applicable laws and any additional terms associated with the content.
            </Typography>

            <Typography variant="body1" gutterBottom>
                <strong>Indemnification</strong>
            </Typography>

            <Typography variant="body1" gutterBottom>
                You agree to indemnify, defend, and hold Exam Eve and its officers, directors, employees, and agents harmless from and against any claims, liabilities, losses, damages, costs, and expenses, including attorney's fees, arising out of or in connection with your use of the Service or any violation of these Terms.
            </Typography>

            <Typography variant="body1" gutterBottom>
                <strong>Changes to Terms</strong>
            </Typography>

            <Typography variant="body1" gutterBottom>
                Exam Eve reserves the right to modify or update these Terms at any time. We will provide notice of any material changes, but it is your responsibility to review these Terms periodically. Your continued use of the Service after such changes will signify your acceptance of the updated Terms.
            </Typography>

            <Typography variant="body1" gutterBottom>
                <strong>Contact Information</strong>
            </Typography>

            <Typography variant="body1" gutterBottom>
                If you have any questions or concerns about these Terms or the Service, please contact us at [email address].
            </Typography>

            <Typography variant="body1" gutterBottom>
                By using the Exam Eve Service, you acknowledge that you have read, understood, and agree to these Terms and Conditions.
            </Typography>
        </div>
    );
}