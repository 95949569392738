import * as React from 'react';

//Material UI components
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Divider from '@mui/joy/Divider';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import Card from '@mui/joy/Card';
import CardActions from '@mui/joy/CardActions';
import CardOverflow from '@mui/joy/CardOverflow';
import Chip from '@mui/joy/Chip';

import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { red } from '@mui/material/colors';

// Other compoenents
import ImageMaterial from './ImageMaterial';

function QuestionBox({ courseId, question, options, answers, question_images, number }) {
  const [value, setValue] = React.useState('female');
  const [userWantsToCheck, setUserWantsToCheck] = React.useState(false);
  const [isCorrect, setIsCorrect] = React.useState();

  const handlCheck = () => {
    setUserWantsToCheck(true);
  }

  const handleRadioCheck = (event) => {
    if (userWantsToCheck) {
      return;
    }
    if (event.target.value === options[answers]) {
      setIsCorrect("Correct!");
    } else {
      setIsCorrect("Incorrect!");
    }
    setValue(event.target.value);
  }

  return (
    <Box
        sx={{
          flex: 1,
          width: '100%',
          
        }}
      >  
        <Stack
          spacing={4}
          sx={{
            display: 'flex',
            maxWidth: '1200px',
            // mx: 'auto',
            px: {
              xs: 2,
              md: 6,
            },
            py: {
              xs: 2,
              md: 3,
            },
          }}
        >
          <Card >
            <Box sx={{ mb: 1 }}>
              <Typography level="title-sg" sx={{ fontWeight: 'bold' }}>Question {number}</Typography>
              <Typography level="body-md">
                <span dangerouslySetInnerHTML={{ __html: question }} />
              </Typography>
              {
                question_images !== undefined && 
                // loop through the question_images array and display each image
                question_images.map((image, index) => (
                  <ImageMaterial key={index} courseId={courseId} image_name={image} />
                ))
              }
            </Box>
            <Divider />
            <Stack spacing={2} sx={{ flexGrow: 1 }}>
              <RadioGroup
                aria-label={question}
                name={question}
                value={value}
                onChange={handleRadioCheck}
              >
                {
                  options.map((option, index) => (
                    <FormControlLabel 
                      key={index} 
                      sx={{ my: 0.5 }} 
                      value={option} 
                      control={
                        userWantsToCheck ?
                          <>
                            {
                              option === options[answers] ? 
                                <Radio color="success" checked={option === options[answers]}/>
                              :
                                <Radio sx={{color:red[800]}} color="error" checked={option === options[answers]}/>
                            }
                          </>
                        :
                          <Radio/>
                      } 
                      label={<Typography level="body-md">{option}</Typography>} 
                    />
                  ))
                }
              </RadioGroup>
            </Stack>
            <CardOverflow sx={{ borderTop: '1px solid', borderColor: 'divider' }}>
              <CardActions sx={{ alignSelf: 'flex-end', pt: 2 }}>
                {
                  userWantsToCheck &&
                  <>
                    {
                      isCorrect === "Correct!" ? 
                        <Chip
                          color="success"
                          size="lg"
                          variant="solid"
                        >
                          {isCorrect}
                        </Chip>
                      :
                        <Chip
                          color="danger"
                          size="lg"
                          variant="solid"
                        >
                          {isCorrect}
                        </Chip>
                    }
                  </>
                }
                <Button size="sm" variant="solid" onClick={handlCheck}>
                  Check
                </Button>
              </CardActions>
            </CardOverflow>
          </Card>

        </Stack>
      </Box>
  );

}
export default QuestionBox;