import { useState, useEffect } from 'react';
import { ref, getDownloadURL } from "firebase/storage";
import { storage } from '../firebase';

function ImageMaterial({ courseId, image_name }) {
    const [url, setUrl] = useState('');

    useEffect(() => {
        getDownloadURL(ref(storage, 'question_images/' + courseId + '/' + image_name ))
        .then((url) => {
            setUrl(url)
        })
        .catch((error) => {
            console.log(error)
        });
    }, [courseId]);

    return (
        <div>
            {url !== null && <img src={url} style={{ width: '100%', height: '100%', borderRadius: '6px', marginBottom: '1rem' }} />}
        </div>
    );
}

export default ImageMaterial;
