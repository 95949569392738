
import React from 'react';
import { httpsCallable, getFunctions } from "firebase/functions";
import { loadStripe } from '@stripe/stripe-js';

const functions = getFunctions();
const createStripeCheckout = httpsCallable(functions, "createStripeCheckout");
const stripePromise = loadStripe('pk_test_51OF6ZPFwNdToK3XVn1xVR2A0a0XRy33gbDwmYYSEf9Pg5HcCUOXtJPeZftBjdINWef459YlzZMcl6oGbfjxi4G4000Sh1BW4T3');

const Purchase = () => {

    const handlePurchase = async () => {
        try {
            // Call the createStripeCheckout Firebase callable function here
            const result = await createStripeCheckout({
                mode: 'payment',
                line_items: [
                    {
                        price: 'price_1OiTwlFwNdToK3XV3vBcz5Z9',
                        quantity: 1,
                    },
                ],
            }).then((result) => {
                console.log(result)
                const sessionId = result.data.id;
                return stripePromise.then((stripe) => {
                    return stripe.redirectToCheckout({ sessionId });
                });
            })
            
            console.log(result);
            // Handle the result or perform any necessary actions
        } catch (error) {
            console.error(error);
            // Handle the error
        }
    };

    return (
        <div>
            <h1>Purchase Page</h1>
            <button onClick={handlePurchase}>Purchase</button>
        </div>
    );
};

export default Purchase;
