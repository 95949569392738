import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';

// Tabs
import Account from './Tabs/Account';
import Courses from './Tabs/Courses';
import Home from './Tabs/Home';

//Screens
import QuestionsPage from './Screens/QuestionsPage';
import PassagePage from "./Screens/PassagePage";
import Redeem from "./Screens/Redeem";
import TermsAndConditions from "./Screens/TermsAndConditions";
import PrivacyPolicy from "./Screens/PrivacyPolicy";
import EssayPage from "./Screens/EssayPage";
import ConversationPage from "./Screens/ConversationPage";
import Purchase from "./Screens/Purchase"
import MyExams from "./Screens/MyExams";

//Components
import NavigationBar from './components/NavigationBar';

//Auth Pages
import Signin from './AuthPages/signin';
import SignUp from './AuthPages/signup';
import NotVerified from './AuthPages/notVerified';
import Verify from './AuthPages/verify';
import ResetPassword from './AuthPages/resetpassword';

function App() {
  return (
    <Router>
      <HelmetProvider>
        <Helmet>
          <title>Master Your Exams,Shape your Future.</title>
          <meta name="description" content="Master Your Exams,Shape your Future. Everything from AWS, GCP to GRE exams." />
        </Helmet>
      </HelmetProvider>
      <NavigationBar/>
      <script
        async
        src="https://js.stripe.com/v3/buy-button.js">
      </script>
      <Routes>
        {/* <Route path="/:courseid" element={<QuestionsPage/>}/> */}
        <Route path="/:courseId/:page" element={<QuestionsPage/>}/>
        <Route path="/passage/:courseId/:page" element={<PassagePage/>}/>
        <Route path="/essay/:courseId/:page" element={<EssayPage/>}/>
        <Route path="/conversation/:courseId/:page" element={<ConversationPage/>}/>
        <Route path="/account" element={<Account/>}/>
        <Route path="/purchase" element={<Purchase/>}/>
        <Route path="/signin" element={<Signin/>}/>
        <Route path="/signup" element={<SignUp/>}/>
        <Route path="/notverified" element={<NotVerified/>}/>
        <Route path="/verify" element={<Verify/>}/>
        <Route path="/redeem" element={<Redeem/>}/>
        <Route path="/resetpassword" element={<ResetPassword/>}/>
        <Route path="/exams" element={<Courses/>}/>
        <Route path="/" element={<Home/>}/>
        <Route path="/termsandconditions" element={<TermsAndConditions/>}/>
        <Route path="/privacypolicy" element={<PrivacyPolicy/>}/>
        <Route path='/myexams' element={<MyExams/>}/>
      </Routes>
    </Router>
  );
}

export default App;