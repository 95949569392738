import * as React from 'react';
import AspectRatio from '@mui/joy/AspectRatio';
import Box from '@mui/joy/Box';
import Container from '@mui/joy/Container';
import { typographyClasses } from '@mui/joy/Typography';
import Button from '@mui/joy/Button';
import Link from '@mui/joy/Link';
import Typography from '@mui/joy/Typography';
import ArrowForward from '@mui/icons-material/ArrowForward';

import { useNavigate } from 'react-router-dom';

const NotVerified = () => {
  const navigate = useNavigate();
  return (
      <Container
        sx={(theme) => ({
          position: 'relative',
          mt: 10,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          py: 10,
          gap: 4,
          [theme.breakpoints.up(834)]: {
            flexDirection: 'row',
            gap: 6,
          },
          [theme.breakpoints.up(1199)]: {
            gap: 12,
          },
        })}
      >
        <Box
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '1rem',
            maxWidth: '50ch',
            textAlign: 'center',
            flexShrink: 999,
            [theme.breakpoints.up(834)]: {
              minWidth: 420,
              alignItems: 'flex-start',
              textAlign: 'initial',
            },
            [`& .${typographyClasses.root}`]: {
              textWrap: 'balance',
            },
          })}
        >
          <Typography
              level="h1"
              fontWeight="xl"
              fontSize="clamp(1.875rem, 1.3636rem + 2.1818vw, 3rem)"
          >
              Awaiting Verification from your Email Address
          </Typography>
          <Typography fontSize="lg" textColor="text.secondary" lineHeight="lg">
              Unless you verify your email address, you will not be able to access the full features of Exam Eve.
          </Typography>
          <Button
            size="lg"
            endDecorator={<ArrowForward fontSize="xl" />}
            sx={{ mt: 2, mb: 1 }}
            onClick={()=> navigate("/signin")}
          >
            Sign In
          </Button>
        </Box>
        <AspectRatio
          ratio={600 / 520}
          variant="outlined"
          maxHeight={300}
          sx={(theme) => ({
            minWidth: 300,
            alignSelf: 'stretch',
            [theme.breakpoints.up(834)]: {
              alignSelf: 'initial',
              flexGrow: 1,
              '--AspectRatio-maxHeight': '520px',
              '--AspectRatio-minHeight': '400px',
            },
            borderRadius: 'sm',
            bgcolor: 'background.level2',
            flexBasis: '50%',
          })}
        >
          <img
            src="https://images.unsplash.com/photo-1483791424735-e9ad0209eea2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80"
            alt=""
          />
        </AspectRatio>
      </Container>
  );
};

export default NotVerified;
