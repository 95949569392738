import * as React from 'react';
import Table from '@mui/joy/Table';
import { useState, useEffect } from 'react';
import { auth } from '../firebase';
import { db } from '../firebase';
import { doc, getDoc } from "firebase/firestore";
import { signOut } from "firebase/auth";
import { useNavigate } from 'react-router-dom';
import Link from '@mui/joy/Link';
import Box from '@mui/joy/Box';
import { Button } from '@mui/joy';


function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Cupcake', 305, 3.7, 67, 4.3),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
];

export default function MyExams() {
  const navigate = useNavigate();
  const [userData, setUserData] = useState({})

  async function getUserData() {
    auth.onAuthStateChanged(async (user) => {
      if(user !== null && user.displayName === null){
        navigate("/verify")
      }

      if(user !== null && user.emailVerified === false){
        signOut(auth).then((event) => {
          console.log(event)
          // Sign-out successful.
          // go to page notverified
          navigate("/notverified")
        })
      }

      await getDoc(doc(db, "users", user.uid)).then((doc) => {
        console.log(doc.data());
        if (doc.exists()) {
          setUserData(doc.data());

          Object.entries(userData["accessibleCourses"]).map(([courseName, expireDate]) => {
            // const expireDateTime = new Date(expireDate);
            console.log(courseName, expireDate.toDate().toLocaleString('en-US', { dateStyle: 'short', timeStyle: 'short' }));
          })
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      }).catch((error) => {
        console.log("Error getting document:", error);
      });
    });
  }

  function isCourseExpired(date) {
    const currentDate = new Date();
    return currentDate > date;
  }

  useEffect(() => {
    getUserData()
  }, [])

  return (
    <Box sx={{ m: 2, display: 'flex', justifyContent: 'center', alignContent:'center' }}>
      <Box sx={{ m: 2, display: 'flex', justifyContent: 'center', width: 1000 }}>
        <Table sx={{ '& thead th:nth-child(1)': { width: '40%' } }}>
          <thead>
            <tr>
              <th>Course Name</th>
              <th>Expiration Date</th>
              <th>Purchase Date</th> {/* Add new column for purchase date */}
              <th>Status</th> {/* Add new column for status */}
              <th></th>
            </tr>
          </thead>
          <tbody>
            {userData["accessibleCourses"] != null && Object.entries(userData["accessibleCourses"]).map(([courseName, expireDate]) => {
              const purchaseDate = new Date(expireDate.toDate());
              purchaseDate.setDate(purchaseDate.getDate() - 14); // Subtract 14 days from expiration date
              return (
                <tr key={courseName}>
                  <td>
                    <Link href={`/${courseName}/1`}>{courseName}</Link>
                  </td>
                  <td>{expireDate.toDate().toLocaleString('en-US', { dateStyle: 'short', timeStyle: 'short' })}</td>
                  <td>{purchaseDate.toLocaleString('en-US', { dateStyle: 'short', timeStyle: 'short' })}</td>
                  <td style={{ color: isCourseExpired(expireDate.toDate()) ? 'red' : 'green' }}>
                    {isCourseExpired(expireDate.toDate()) ? 'Expired' : 'Active'}
                  </td>
                  <td>
                    <Button variant="outlined" color="primary" onClick={() => navigate(`/${courseName}/1`)}>
                      View Course
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Box>
    </Box>
  );
}