import React, { useState } from 'react';
import { Button, Typography, Box, AccordionGroup, Accordion, AccordionSummary, AccordionDetails, Chip, Stack } from '@mui/joy';
import { TextField } from '@mui/material';
import { db, auth } from '../firebase';
import { addDoc, collection, query, where, onSnapshot, orderBy, limit } from 'firebase/firestore';
import Alert from '@mui/material/Alert';
import LinearProgress from '@mui/material/LinearProgress';
import { useParams } from "react-router-dom";

export default function Essay({ courseId, question, number }) {
    const [user, setUser] = useState(null);
    const [essayQuestion, setEssayQuestion] = useState(question); 
    const [loading, setLoading] = useState(false);
    const [essayText, setEssayText] = useState('');
    const [submissions, setSubmissions] = useState([]); 
    const params = useParams();
    const [pageInput, setPageInput] = useState(params.page);

    const handleEssayChange = (event) => {
        setEssayText(event.target.value);
    };

    const handleEssaySubmit = async () => {
        setLoading(true);

        await addDoc(collection(db, "essays"), {
            answer: essayText
            , question: essayQuestion
            , number: parseInt(number)
            , courseId: courseId
            , uid: user.uid
            , status: "processing"
            , createdAt: new Date()
        }).then((result) => {
            console.log(result);
        }).catch((error) => {
            console.log(error);
        });

        setEssayText('');
    };

    const newPage = () => {
        let unsubscribe_responses;
        const unsubscribe = auth.onAuthStateChanged((authUser) => {
            if (authUser) {
                console.log(authUser)
                setUser(authUser);

                const q = query(collection(db, "essays"), where("courseId", "==", courseId), where("number", "==", parseInt(number)), where("uid", "==", authUser.uid), orderBy("createdAt", "desc"), limit(10));
                unsubscribe_responses = onSnapshot(q, (querySnapshot) => {
                    const attempts = [];
                    querySnapshot.forEach((doc) => {
                        let data = doc.data()
                        if(data.response !== undefined){
                            try{
                                let grade = data.response.split("Why this Grade was give:")[0].split("Grade: ")[1].split("/")[0];
                                let whyGrade = data.response.split("Why this Grade was give:")[1].split("What could be improved:")[0];
                                let whatImprove = data.response.split("What could be improved:")[1].split("Grammar and Spelling mistakes:")[0];
                                let grammarSpelling = data.response.split("Grammar and Spelling mistakes:")[1].split("Correct Answer/Ideal Answer:")[0].split("-");
                                console.log({
                                    id: doc.id
                                    , ...data
                                    , grade: parseInt(grade)
                                    , whyGrade: whyGrade
                                    , whatImprove: whatImprove
                                    , grammarSpelling: grammarSpelling
                                    , ...data
                                    })
                                attempts.push({
                                    id: doc.id
                                    , ...data
                                    , grade: parseInt(grade)
                                    , whyGrade: whyGrade
                                    , whatImprove: whatImprove
                                    , grammarSpelling: grammarSpelling
                                    , ...data
                                    });
                            }catch{
                                attempts.push({
                                    id: doc.id
                                    , ...data
                                });
                            }
                        }else{
                            attempts.push({
                                id: doc.id
                                , ...doc.data()
                            });
                        }
                    });
                    setSubmissions(attempts);
                });
            } else {
                setUser(null);
            }
        });
        return () => {
            unsubscribe();
        };
    }

    useState(() => {
        newPage();
    }, [pageInput]);

    useState(() => {
        newPage();
    }, []);

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            width="60%"
            margin="auto"
        >
            <Box width="100%" display="flex" flexDirection="column" sx={{ mt: 5 }}>
                <Typography variant="h6">
                    Question {number}
                </Typography>
                <Typography variant="h3">
                    {essayQuestion}
                </Typography>
                <TextField
                    multiline
                    rows={20} // Increase the value of rows to make the text field bigger
                    value={essayText}
                    onChange={handleEssayChange}
                    placeholder="Write your essay here"
                />
                <Button onClick={handleEssaySubmit}>
                    Submit
                </Button>
                <AccordionGroup sx={{ mt: 5, mb: 5 }} spacing={2}>
                    {
                        submissions.map((submission, index) => (
                            <Accordion key={index}>
                                <AccordionSummary
                                    avatar={<Typography>{submission.number}</Typography>}
                                >
                                    <Box sx={{ justifyContent: 'space-between', display:'flex', flexDirection:'row', width:'100%' }}>
                                        <Typography>Attempt ID: {submission.id}</Typography>
                                        {
                                            submission.createdAt !== undefined &&
                                            <Typography>
                                                {submission.createdAt.toDate().toLocaleString().slice(0, -3).replace(/,/g, '')}
                                            </Typography>
                                        }
                                        {
                                            submission.status === "processing" ?
                                                <Chip color="neutral" variant="solid">{submission.status}</Chip>
                                            :
                                            <>
                                                {
                                                    submission.grade <= 50 &&
                                                    <Chip color="danger" variant="solid">{submission.grade}</Chip>
                                                }
                                                {
                                                    submission.grade > 50 && submission.grade < 70 &&
                                                    <Chip color="neutral" variant="solid">{submission.grade}</Chip>
                                                }
                                                {
                                                    submission.grade >= 70 &&
                                                    <Chip color="success" variant="solid">{submission.grade}</Chip>
                                                }
                                            </>
                                        }
                                    </Box>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography variant="h2">
                                        <b>Results</b>
                                    </Typography>
                                    {
                                        submission.status === "finished" &&
                                        <>
                                            <Typography variant="h2">
                                                <b>Grade</b>
                                            </Typography>
                                            <Typography variant="h6">
                                                {submission.grade}
                                            </Typography>
                                            <Typography variant="h2">
                                                <b>Why this Grade was given</b>
                                            </Typography>
                                            <Typography variant="h6">
                                                {submission.whyGrade}
                                            </Typography>
                                            <Typography variant="h2">
                                                <b>What could be improved</b>
                                            </Typography>
                                            <Typography variant="h6">
                                                {submission.whatImprove}
                                            </Typography>
                                            <Typography variant="h2">
                                                <b>Grammar and Spelling mistakes</b>
                                            </Typography>
                                            <Stack direction="column" spacing={2}>
                                                {
                                                    submission.grammarSpelling.map((grammar, index) => (
                                                        <Typography variant="h6" key={index}>
                                                            {grammar}
                                                        </Typography>
                                                    ))
                                                }
                                            </Stack>
                                            <Typography variant="h2">
                                                <b>Submission</b>
                                            </Typography>
                                        </>
                                    }
                                    <Typography variant="h6">
                                        {submission.answer}
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        ))
                    }
                </AccordionGroup>
            </Box>
        </Box>
    );
}
