
export const possibleOccupations = [
    "Teacher"
    ,"Student"
    ,"IT Professional"
    ,"Online Tutor"
    ,"Data Analyst"
    ,"Software Developer"
    ,"Web Designer"
    ,"Graphic Designer"
    ,"Online Course Creator"
    ,"Content Writer"
    ,"SEO Specialist"
    ,"Marketing Manager"
    ,"Social Media Manager"
    ,"Business Analyst"
    ,"Project Manager"
    ,"Online Coach"
    ,"Digital Marketer"
    ,"Online Salesperson"
    ,"E-commerce Manager"
    ,"Virtual Assistant"
    ,"Online Researcher"
    ,"Online Proctor"
    ,"Cybersecurity Analyst"
    ,"Online Test Administrator"
    ,"Quality Assurance Tester"
    ,"Distance Education Instructor"
    ,"Online Moderator"
    ,"EdTech Specialist"
    ,"Instructional Designer"
    ,"Online Course Developer"
    ,"Online Exam Coordinator"
    ,"Online Assessment Manager"
    ,"Video Content Creator"
    ,"Online Quiz Creator"
    ,"Remote Proctoring Specialist"
    ,"Online Education Consultant"
    ,"Curriculum Developer"
    ,"Test Preparation Instructor"
    ,"Online Exam Supervisor"
    ,"Online Course Facilitator"
    ,"Online Test Coordinator"
    ,"Online Assessment Developer"
    ,"Learning Management System (LMS) Administrator"
    ,"Student Support Specialist"
    ,"Virtual Classroom Manager"
    ,"Online Learning Coach"
    ,"Instructional Technologist"
    ,"Online Course Coordinator"
    ,"Online Training Manager"
    ,"Online Learning Administrator"
    ,"Test Item Writer"
    ,"Online Quiz Designer"
    ,"Online Curriculum Manager"
    ,"Exam Security Analyst"
    ,"Online Course Manager"
    ,"Virtual Learning Coordinator"
    ,"Online Learning Specialist"
    ,"Online Exam Proctor"
    ,"Test Content Developer"
    ,"Online Learning Advisor"
    ,"Exam Monitoring Specialist"
    ,"Online Curriculum Developer"
    ,"Remote Test Administrator"
    ,"Online Assessment Coordinator"
    ,"EdTech Integration Specialist"
    ,"Online Content Manager"
    ,"Online Assessment Supervisor"
    ,"Digital Assessment Coordinator"
    ,"Online Learning Coordinator"
    ,"Test Validation Specialist"
    ,"Online Exam Moderator"
    ,"E-learning Consultant"
    ,"Online Training Coordinator"
    ,"Test Security Officer"
    ,"Virtual Learning Advisor"
    ,"Online Course Administrator"
    ,"Test Center Manager"
    ,"Online Education Coordinator"
    ,"Online Test Security Specialist"
    ,"Learning Platform Administrator"
    ,"Online Exam Manager"
    ,"Online Assessment Advisor"
    ,"Virtual Learning Facilitator"
    ,"Test Compliance Officer"
    ,"Online Curriculum Coordinator"
    ,"Assessment Content Developer"
    ,"Online Learning Program Manager"
    ,"Exam Integrity Coordinator"
    ,"Online Quiz Manager"
    ,"Assessment Design Specialist"
    ,"Virtual Learning Developer"
    ,"Online Training Supervisor"
    ,"Test Proctoring Coordinator"
    ,"Online Course Facilitation Specialist"
    ,"Assessment Security Analyst"
    ,"EdTech Curriculum Developer"
    ,"Online Assessment Facilitator"
    ,"Digital Learning Coordinator"
    ,"Remote Exam Coordinator"
    ,"Other"
]
export const possibleUniversity = [
    "Harvard University"
    ,"Massachusetts Institute of Technology"
    ,"Stanford University"
    ,"University of Cambridge"
    ,"California Institute of Technology"
    ,"Princeton University"
    ,"University of Oxford"
    ,"Yale University"
    ,"Columbia University"
    ,"University of California, Berkeley"
    ,"University of Chicago"
    ,"Cornell University"
    ,"University of Pennsylvania"
    ,"University of Tokyo"
    ,"Johns Hopkins University"
    ,"Swiss Federal Institute of Technology in Zurich"
    ,"Kyoto University"
    ,"Weizmann Institute of Science"
    ,"University of California, Los Angeles"
    ,"University of California, San Diego"
    ,"Rockefeller University"
    ,"Hebrew University of Jerusalem"
    ,"New York University"
    ,"University of California, San Francisco"
    ,"University of Wisconsin–Madison"
    ,"University of Illinois at Urbana–Champaign"
    ,"Duke University"
    ,"Imperial College London"
    ,"University of Texas Southwestern Medical Center"
    ,"University of Texas at Austin"
    ,"University College London"
    ,"Osaka University"
    ,"Northwestern University"
    ,"University of Michigan, Ann Arbor"
    ,"University of Toronto"
    ,"University of North Carolina at Chapel Hill"
    ,"Washington University in St. Louis"
    ,"University of Utah"
    ,"University of Washington - Seattle"
    ,"University of California, Santa Barbara"
    ,"McGill University"
    ,"Purdue University, West Lafayette"
    ,"Carnegie Mellon University"
    ,"University of Southern California"
    ,"University of California, Davis"
    ,"University of Colorado Boulder"
    ,"University of California, Irvine"
    ,"University of Paris-Sud"
    ,"University of Minnesota, Twin Cities"
    ,"University of Arizona"
    ,"Technion – Israel Institute of Technology"
    ,"Ohio State University, Columbus"
    ,"University of Rochester"
    ,"École normale supérieure - Paris"
    ,"University of Florida"
    ,"Tel Aviv University"
    ,"Rice University"
    ,"Dartmouth College"
    ,"Vanderbilt University"
    ,"University of Edinburgh"
    ,"École Polytechnique"
    ,"Boston University"
    ,"Pierre-and-Marie-Curie University"
    ,"Pennsylvania State University, University Park"
    ,"University of Geneva"
    ,"Brown University"
    ,"University of British Columbia"
    ,"University of Maryland, College Park"
    ,"Swiss Federal Institute of Technology in Lausanne"
    ,"Karolinska Institute"
    ,"University of Pittsburgh - Pittsburgh Campus"
    ,"Rutgers University-New Brunswick"
    ,"Texas A&M University, College Station"
    ,"Nagoya University"
    ,"Seoul National University"
    ,"University of Manchester"
    ,"Sapienza University of Rome"
    ,"Case Western Reserve University"
    ,"Tohoku University"
    ,"Arizona State University"
    ,"Emory University"
    ,"Ruprecht Karl University of Heidelberg"
    ,"Ludwig Maximilian University of Munich"
    ,"University of Virginia"
    ,"Leiden University"
    ,"Tufts University"
    ,"Georgia Institute of Technology"
    ,"University of Zurich"
    ,"University of Helsinki"
    ,"Technical University of Munich"
    ,"University of Oslo"
    ,"University of Queensland"
    ,"University of Copenhagen"
    ,"University of Sydney"
    ,"Williams College"
    ,"University of Texas MD Anderson Cancer Center"
    ,"University of Nottingham"
    ,"University of Bristol"
    ,"Utrecht University"
    ,"Mines ParisTech"
    ,"Keio University"
    ,"King's College London"
    ,"Indiana University - Bloomington"
    ,"National University of Singapore"
    ,"Stony Brook University"
    ,"Lomonosov Moscow State University"
    ,"Australian National University"
    ,"University of Alberta"
    ,"Paris Diderot University - Paris 7"
    ,"Waseda University"
    ,"Peking University"
    ,"University of Notre Dame"
    ,"National Taiwan University"
    ,"Tsinghua University"
    ,"Kyushu University"
    ,"Katholieke Universiteit Leuven"
    ,"Tokyo Institute of Technology"
    ,"University of Melbourne"
    ,"University of Bonn"
    ,"Royal Institute of Technology"
    ,"Erasmus University Rotterdam"
    ,"Yonsei University"
    ,"Michigan State University"
    ,"University of the Witwatersrand"
    ,"Lund University"
    ,"Georgetown University"
    ,"Aarhus University"
    ,"University of Barcelona"
    ,"Baylor College of Medicine"
    ,"University of Amsterdam"
    ,"Uppsala University"
    ,"University of Iowa"
    ,"University of Groningen"
    ,"Korea Advanced Institute of Science and Technology (KAIST)"
    ,"Radboud University Nijmegen"
    ,"Korea University"
    ,"University of São Paulo"
    ,"University of Colorado Denver"
    ,"University of Montreal"
    ,"Albert Ludwig University of Freiburg"
    ,"Ghent University"
    ,"University of Glasgow"
    ,"University of Miami"
    ,"Université libre de Bruxelles"
    ,"McMaster University"
    ,"University of Birmingham"
    ,"University of Texas at Dallas"
    ,"VU University Amsterdam"
    ,"Wake Forest University"
    ,"University of Southampton"
    ,"Nanyang Technological University"
    ,"University of New South Wales"
    ,"Osaka City University"
    ,"University of Leeds"
    ,"Western University (The University of Western Ontario)"
    ,"Kobe University"
    ,"University of Basel"
    ,"University of Hong Kong"
    ,"Indiana University-Purdue University Indianapolis"
    ,"Humboldt University of Berlin"
    ,"Free University of Berlin"
    ,"University of Tübingen"
    ,"Goethe University Frankfurt"
    ,"University of Houston"
    ,"University of California, Santa Cruz"
    ,"University of Alabama at Birmingham"
    ,"Icahn School of Medicine at Mount Sinai"
    ,"University of Calgary"
    ,"University of Gothenburg"
    ,"University of Göttingen"
    ,"Monash University"
    ,"Southern Methodist University"
    ,"Yeshiva University"
    ,"University of South Florida - Tampa"
    ,"Hanyang University"
    ,"Oregon Health & Science University"
    ,"University of Strasbourg"
    ,"University of Cincinnati"
    ,"University of Sheffield"
    ,"University of Hamburg"
    ,"George Washington University"
    ,"University of Milan"
    ,"Iowa State University"
    ,"University of Maryland, Baltimore"
    ,"University of Padua"
    ,"University of New Mexico"
    ,"Pohang University of Science and Technology"
    ,"Stockholm University"
    ,"Hokkaido University"
    ,"Fudan University"
    ,"University of Sussex"
    ,"Université catholique de Louvain"
    ,"Wayne State University"
    ,"University of Massachusetts Medical School"
    ,"University of Lausanne"
    ,"Shanghai Jiao Tong University"
    ,"University of Missouri–Columbia"
    ,"Technical University of Denmark"
    ,"Complutense University of Madrid"
    ,"University of Cologne"
    ,"Trinity College, Dublin"
    ,"University of Bern"
    ,"Chinese University of Hong Kong"
    ,"University of Kansas"
    ,"Sungkyunkwan University"
    ,"University of Vienna"
    ,"Zhejiang University"
    ,"University of Georgia"
    ,"University of Massachusetts Amherst"
    ,"Cardiff University"
    ,"University of Münster"
    ,"University of Liverpool"
    ,"University of Ottawa"
    ,"University of Erlangen-Nuremberg"
    ,"Queen Mary University of London"
    ,"Karlsruhe Institute of Technology"
    ,"Louisiana State University - Baton Rouge"
    ,"Nanjing University"
    ,"University of Illinois at Chicago"
    ,"University of Bologna"
    ,"University of Manitoba"
    ,"Joseph Fourier University"
    ,"University of California, Riverside"
    ,"Julius-Maximilian's University of Würzburg"
    ,"Aix-Marseille University"
    ,"University of Western Australia"
    ,"Laval University"
    ,"University of Tennessee, Knoxville"
    ,"North Carolina State University"
    ,"Newcastle University"
    ,"Claude Bernard University Lyon 1"
    ,"University of Turin"
    ,"University of Kiel"
    ,"University of Connecticut"
    ,"Tulane University"
    ,"University of Oklahoma - Norman Campus"
    ,"Brandeis University"
    ,"Dresden University of Technology"
    ,"RWTH Aachen University"
    ,"University of Texas Health Science Center at San Antonio"
    ,"Virginia Commonwealth University"
    ,"University of Montpellier 2"
    ,"Dalian University of Technology"
    ,"Rensselaer Polytechnic Institute"
    ,"Paul Sabatier University - Toulouse III"
    ,"Paris Descartes University"
    ,"National Cheng Kung University"
    ,"University of Delaware"
    ,"Vrije Universiteit Brussel"
    ,"Johannes Gutenberg University of Mainz"
    ,"Maastricht University"
    ,"University of Düsseldorf"
    ,"University of Dundee"
    ,"University of Tsukuba"
    ,"Autonomous University of Barcelona"
    ,"Florida State University"
    ,"University of Texas at San Antonio"
    ,"London School of Hygiene & Tropical Medicine"
    ,"Lehigh University"
    ,"University of Kentucky"
    ,"Thomas Jefferson University"
    ,"Drexel University"
    ,"Colorado State University - Fort Collins"
    ,"University of Leicester"
    ,"Durham University"
    ,"Oregon State University"
    ,"University of Florence"
    ,"University of Cape Town"
    ,"University at Buffalo, The State University of New York"
    ,"University College Dublin"
    ,"University of Science and Technology of China"
    ,"Virginia Polytechnic Institute and State University"
    ,"University of Naples Federico II"
    ,"University of Auckland"
    ,"University of Texas Health Science Center at Houston"
    ,"Delft University of Technology"
    ,"University of Aberdeen"
    ,"Queen's University"
    ,"University of Lisbon"
    ,"Hiroshima University"
    ,"University of Waterloo"
    ,"Okayama University"
    ,"University of Ulm"
    ,"Chulalongkorn University"
    ,"Dalhousie University"
    ,"Saint Petersburg State University"
    ,"Norwegian University of Science and Technology"
    ,"University of Reading"
    ,"Wageningen University and Research Centre"
    ,"Umeå University"
    ,"University of Porto"
    ,"Hong Kong University of Science and Technology"
    ,"Sun Yat-sen University"
    ,"Medical College of Wisconsin"
    ,"City College of New York"
    ,"Hannover Medical School"
    ,"Washington State University, Pullman"
    ,"Technical University of Berlin"
    ,"University of South Carolina - Columbia"
    ,"Leipzig University"
    ,"University of Stuttgart"
    ,"University of Valencia"
    ,"Linköping University"
    ,"University of Duisburg-Essen"
    ,"Ruhr University Bochum"
    ,"University of Bergen"
    ,"London School of Economics"
    ,"Wesleyan University"
    ,"University of Pisa"
    ,"Autonomous University of Madrid"
    ,"Medical University of Vienna"
    ,"Stellenbosch University"
    ,"Medical University of South Carolina"
    ,"Charles University in Prague"
    ,"Kyungpook National University"
    ,"University of Science, Malaysia"
    ,"University of Liège"
    ,"University of Hull"
    ,"Northeastern University"
    ,"Kansas State University"
    ,"University of Vermont"
    ,"Rush University"
    ,"University of Louisville"
    ,"University of St Andrews"
    ,"University of Warwick"
    ,"University of Rome Tor Vergata"
    ,"University of Innsbruck"
    ,"Darmstadt University of Technology"
    ,"Indian Institute of Technology Delhi"
    ,"Federal University of Rio de Janeiro"
    ,"University of Antwerp"
    ,"National and Kapodistrian University of Athens"
    ,"Boston College"
    ,"Vita-Salute San Raffaele University"
    ,"Gwangju Institute of Science and Technology"
    ,"University of Nebraska–Lincoln"
    ,"University of York"
    ,"National Autonomous University of Mexico"
    ,"University of Regensburg"
    ,"Yokohama National University"
    ,"University of Adelaide"
    ,"University of Genoa"
    ,"Queen's University Belfast"
    ,"University of Marburg"
    ,"University of Pavia"
    ,"St George's, University of London"
    ,"University of Oregon"
    ,"University of Navarra"
    ,"San Diego State University"
    ,"Nankai University"
    ,"University of East Anglia"
    ,"University of Nebraska Medical Center"
    ,"University of Southern Denmark"
    ,"Eindhoven University of Technology"
    ,"Xiamen University"
    ,"University of Otago"
    ,"University of Santiago de Compostela"
    ,"University of Exeter"
    ,"University of Jena"
    ,"University of Perugia"
    ,"Justus Liebig University Giessen"
    ,"College of William and Mary"
    ,"Ben-Gurion University of the Negev"
    ,"Saint Louis University"
    ,"Colorado School of Mines"
    ,"Oklahoma State University–Stillwater"
    ,"University of Guelph"
    ,"University of Trieste"
    ,"University of Verona"
    ,"University at Albany, SUNY"
    ,"Tongji University"
    ,"Eötvös Loránd University"
    ,"University of Alabama - Tuscaloosa"
    ,"University of Eastern Finland"
    ,"Ewha Womans University"
    ,"City University of Hong Kong"
    ,"Tokyo Medical and Dental University"
    ,"University of Victoria"
    ,"University of Buenos Aires"
    ,"University of Texas Medical Branch at Galveston"
    ,"Kyung Hee University"
    ,"Hong Kong Polytechnic University"
    ,"Saarland University"
    ,"Scuola Normale Superiore di Pisa"
    ,"University of Seville"
    ,"Beijing Normal University"
    ,"Chiba University"
    ,"University of Ferrara"
    ,"University of Bari Aldo Moro"
    ,"Polytechnic University of Milan"
    ,"Temple University"
    ,"University of Turku"
    ,"Aalto University"
    ,"Simon Fraser University"
    ,"Tianjin University"
    ,"Chalmers University of Technology"
    ,"Middle East Technical University"
    ,"Cranfield University"
    ,"York University"
    ,"Xi'an Jiaotong University"
    ,"Chonnam National University"
    ,"University of Central Florida"
    ,"University of Lorraine"
    ,"University of Bordeaux II"
    ,"University of New Hampshire"
    ,"Versailles Saint-Quentin-en-Yvelines University"
    ,"Chonbuk National University"
    ,"Kumamoto University"
    ,"Huazhong University of Science and Technology"
    ,"Brunel University"
    ,"Polytechnic University of Catalonia"
    ,"National Chiao Tung University"
    ,"Southeast University"
    ,"Tokyo University of Science"
    ,"Catholic University of the Sacred Heart"
    ,"Brigham Young University"
    ,"Technical University of Madrid"
    ,"Nagasaki University"
    ,"National Tsing Hua University"
    ,"University of Warsaw"
    ,"King Saud University"
    ,"University of Bath"
    ,"Concordia University"
    ,"Georgia Regents University"
    ,"Niigata University"
    ,"University of Oulu"
    ,"Pompeu Fabra University"
    ,"Vienna University of Technology"
    ,"Sogang University"
    ,"Gyeongsang National University"
    ,"University of Tampere"
    ,"University of Milan - Bicocca"
    ,"University of Bordeaux I"
    ,"Swedish University of Agricultural Sciences"
    ,"George Mason University"
    ,"Jilin University"
    ,"University of Delhi"
    ,"University of Campinas"
    ,"Wuhan University"
    ,"West Virginia University"
    ,"Pusan National University"
    ,"University of Mississippi - Oxford Campus"
    ,"University of Arkansas for Medical Sciences"
    ,"Miami University"
    ,"University of Tokushima"
    ,"University of Twente"
    ,"Loyola University Chicago"
    ,"Ajou University"
    ,"Syracuse University"
    ,"Lancaster University"
    ,"Pontifical Catholic University of Chile"
    ,"University of Chile"
    ,"Innsbruck Medical University"
    ,"University of Granada"
    ,"Kanazawa University"
    ,"University of Parma"
    ,"University of Siena"
    ,"Shandong University"
    ,"Technical University of Dortmund"
    ,"University of KwaZulu-Natal"
    ,"University of Nevada, Reno"
    ,"Yokohama City University"
    ,"École normale supérieure de Lyon"
    ,"University College Cork"
    ,"Ohio University"
    ,"Central South University"
    ,"University of Modena and Reggio Emilia"
    ,"New Mexico State University"
    ,"University of Konstanz"
    ,"University of Hawaii at Manoa"
    ,"University of Nebraska Omaha"
    ,"Shinshu University"
    ,"University of Strathclyde"
    ,"Baylor University"
    ,"Polytechnic University of Valencia"
    ,"Harbin Institute of Technology"
    ,"University of Rennes 1"
    ,"University of Arkansas - Fayetteville"
    ,"Aristotle University of Thessaloniki"
    ,"Jagiellonian University"
    ,"Texas Tech University (TTU)"
    ,"University of Rostock"
    ,"University of Akron"
    ,"University of Saskatchewan"
    ,"Juntendo University"
    ,"University of Wyoming"
    ,"Macquarie University"
    ,"University of Canterbury"
    ,"University of Denver"
    ,"Illinois Institute of Technology"
    ,"Creighton University"
    ,"Bielefeld University"
    ,"University of Malaya"
    ,"University of Ulsan"
    ,"City University London"
    ,"Grenoble Institute of Technology"
    ,"Federal University of Minas Gerais"
    ,"University of Hanover"
    ,"South China University of Technology"
    ,"University of Surrey"
    ,"Otto-von-Guericke University Magdeburg"
    ,"Indian Institute of Science"
    ,"Tokyo Metropolitan University"
    ,"Bar-Ilan University"
    ,"Mississippi State University"
    ,"University of Maryland, Baltimore County"
    ,"University of Wollongong"
    ,"Flinders University"
    ,"Martin Luther University of Halle-Wittenberg"
    ,"Graduate University for Advanced Studies"
    ,"Clemson University"
    ,"University of Brescia"
    ,"Georgia State University"
    ,"Dongguk University"
    ,"University of Fribourg"
    ,"Sichuan University"
    ,"University of Iceland"
    ,"University of Sherbrooke"
    ,"University of Graz"
    ,"University of Málaga"
    ,"University of Zaragoza"
    ,"New York Medical College"
    ,"SUNY Downstate Medical Center"
    ,"École Centrale Paris"
    ,"Utah State University"
    ,"University of Ljubljana"
    ,"University of Montpellier 1"
    ,"Victoria University of Wellington"
    ,"East China University of Science and Technology"
    ,"Konkuk University"
    ,"University of Potsdam"
    ,"University of the Basque Country"
    ,"University of Nice Sophia Antipolis"
    ,"University of Udine"
    ,"National Yang-Ming University"
    ,"Indian Institute of Technology Bombay"
    ,"University of Bayreuth"
    ,"University of Wisconsin–Milwaukee"
    ,"University of Dayton"
    ,"Nihon University"
    ,"Montana State University - Bozeman"
    ,"Royal Holloway, University of London"
    ,"Tokyo Women's Medical University"
    ,"Panjab University"
    ,"Tampere University of Technology"
    ,"University of Coimbra"
    ,"University of Newcastle"
    ,"Nara Institute of Science and Technology"
    ,"Auburn University"
    ,"University of Bremen"
    ,"Mahidol University"
    ,"University of Crete"
    ,"Bangor University"
    ,"University of Aveiro"
    ,"University of Palermo"
    ,"Nagoya City University"
    ,"University of Tartu"
    ,"University of Savoy"
    ,"University of Tromsø - The Arctic University of Norway"
    ,"University of Missouri–Kansas City"
    ,"Beihang University"
    ,"American University of Beirut"
    ,"University of Nantes"
    ,"University of Essex"
    ,"University of Zagreb"
    ,"National Central University"
    ,"University Lille 1: Sciences and Technologies"
    ,"University of Catania"
    ,"National Polytechnic Institute of Toulouse"
    ,"Indian Institute of Technology Kanpur"
    ,"Kagoshima University"
    ,"Lanzhou University"
    ,"James Cook University"
    ,"Shizuoka University"
    ,"Indian Institute of Technology Kharagpur"
    ,"Second University of Naples"
    ,"Indian Institute of Technology Madras"
    ,"Plymouth University"
    ,"International School for Advanced Studies"
    ,"Polytechnic University of Turin"
    ,"University of Oviedo"
    ,"Ehime University"
    ,"University of Ioannina"
    ,"University of Cagliari"
    ,"Tottori University"
    ,"Federal University of Rio Grande do Sul"
    ,"Kent State University"
    ,"University of Los Andes (Colombia)"
    ,"Hunan University"
    ,"Tokai University"
    ,"LSU Health Sciences Center New Orleans"
    ,"Southern Illinois University Carbondale"
    ,"Tata Institute of Fundamental Research"
    ,"Aalborg University"
    ,"Osaka Prefecture University"
    ,"Chang Gung University"
    ,"Northern Illinois University"
    ,"University of Trento"
    ,"Semmelweis University"
    ,"University of Science and Technology Beijing"
    ,"National University of Ireland, Galway"
    ,"Dankook University"
    ,"Istanbul University"
    ,"Tokyo University of Agriculture and Technology"
    ,"University of Montana - Missoula"
    ,"Queensland University of Technology"
    ,"Binghamton University"
    ,"Université de Namur"
    ,"University of Tasmania"
    ,"University of Pretoria"
    ,"Central China Normal University"
    ,"Indian Institute of Technology Roorkee"
    ,"Curtin University"
    ,"Deakin University"
    ,"Tilburg University"
    ,"Inha University"
    ,"University of New Orleans"
    ,"Paris-Est Créteil Val-de-Marne University"
    ,"University of L'Aquila"
    ,"Chungnam National University"
    ,"University of Salamanca"
    ,"University of Alaska Fairbanks"
    ,"University of Poitiers"
    ,"Albany Medical College"
    ,"Birkbeck, University of London"
    ,"Griffith University"
    ,"Federal University of São Paulo"
    ,"Carleton University"
    ,"Massey University"
    ,"Catholic University of Korea"
    ,"Open University (UK)"
    ,"University of Patras"
    ,"Universidade Nova de Lisboa"
    ,"Texas A&M Health Science Center"
    ,"Braunschweig University of Technology"
    ,"National Chung Hsing University"
    ,"La Trobe University"
    ,"Missouri University of Science and Technology"
    ,"University of Idaho"
    ,"Louisiana Tech University"
    ,"University of Portsmouth"
    ,"University of La Laguna"
    ,"Florida International University"
    ,"Medical University of Graz"
    ,"Howard University"
    ,"University of Hertfordshire"
    ,"State University of New York Upstate Medical University"
    ,"Royal College of Surgeons in Ireland"
    ,"National Technical University of Athens"
    ,"University of Windsor"
    ,"Rovira i Virgili University"
    ,"University of Burgundy"
    ,"Gunma University"
    ,"AGH University of Science and Technology"
    ,"Gifu University"
    ,"Hacettepe University"
    ,"Örebro University"
    ,"Memorial University of Newfoundland"
    ,"Kindai University"
    ,"University of Rhode Island"
    ,"University of Alicante"
    ,"New Jersey Institute of Technology"
    ,"Lille 2 University of Health and Law"
    ,"Keele University"
    ,"University of Jyväskylä"
    ,"Rochester Institute of Technology"
    ,"Sapporo Medical University"
    ,"Banaras Hindu University"
    ,"King Abdullah University of Science and Technology"
    ,"Northeast Normal University"
    ,"Portland State University"
    ,"University of Québec at Montreal"
    ,"East China Normal University"
    ,"University of Debrecen"
    ,"Loma Linda University"
    ,"Old Dominion University"
    ,"University of Insubria"
    ,"University of Nevada, Las Vegas"
    ,"Hong Kong Baptist University"
    ,"University of Minho"
    ,"University of Messina"
    ,"University of Texas at Arlington"
    ,"University François Rabelais, Tours"
    ,"UNESP, São Paulo State University"
    ,"Toho University"
    ,"Beijing University of Chemical Technology"
    ,"Rutgers, The State University of New Jersey - Newark"
    ,"Johannes Kepler University of Linz"
    ,"Warsaw University of Technology"
    ,"University of Paris 13"
    ,"University of Toyama"
    ,"University of Toledo"
    ,"University of Vigo"
    ,"University of Kent"
    ,"Jichi Medical University"
    ,"Roma Tre University"
    ,"Chung Yuan Christian University"
    ,"University of Haifa"
    ,"Åbo Akademi University"
    ,"Swansea University"
    ,"China Agricultural University"
    ,"Marche Polytechnic University"
    ,"University of Maine, Orono"
    ,"Heriot-Watt University"
    ,"Marquette University"
    ,"Loughborough University"
    ,"Kaiserslautern University of Technology"
    ,"Aston University"
    ,"University of Girona"
    ,"Hasselt University"
    ,"Nanjing University of Science and Technology"
    ,"University of Natural Resources and Life Sciences, Vienna"
    ,"University of Szeged"
    ,"Soochow University (Suzhou)"
    ,"University of the Balearic Islands"
    ,"Fuzhou University"
    ,"University of Memphis"
    ,"San Francisco State University"
    ,"East Carolina University"
    ,"University of Augsburg"
    ,"University of Missouri–St. Louis"
    ,"Kyoto Prefectural University of Medicine"
    ,"Shanghai University"
    ,"University of South Australia"
    ,"Liverpool John Moores University"
    ,"Graz University of Technology"
    ,"Kitasato University"
    ,"University of North Carolina at Charlotte"
    ,"G. d'Annunzio University, Chieti-Pescara"
    ,"University of Cantabria"
    ,"Miguel Hernández University of Elche"
    ,"Donghua University"
    ,"Comenius University in Bratislava"
    ,"University of Alabama in Huntsville"
    ,"Mie University"
    ,"University of Stirling"
    ,"Hunter College"
    ,"Swinburne University of Technology"
    ,"University of Ulster"
    ,"University of Évry Val d'Essonne"
    ,"University of Salerno"
    ,"National University of La Plata"
    ,"Masaryk University"
    ,"Yamaguchi University"
    ,"University of Yamanashi"
    ,"Showa University"
    ,"North Dakota State University - Main Campus"
    ,"University of North Texas, Denton"
    ,"University of Mississippi Medical Center"
    ,"University of Tehran"
    ,"Paul Valéry University, Montpellier III"
    ,"Wuhan University of Technology"
    ,"University of Hyogo"
    ,"The Catholic University of America"
    ,"University of Neuchâtel"
    ,"National Polytechnic Institute"
    ,"Jikei University School of Medicine"
    ,"University of Valladolid"
    ,"University of Calabria"
    ,"University of Lübeck"
    ,"Kurume University"
    ,"Peking Union Medical College"
    ,"Yamagata University"
    ,"University of Córdoba (Spain)"
    ,"University of Mons"
    ,"Saga University"
    ,"Clarkson University"
    ,"University of South Alabama"
    ,"Inje University"
    ,"University of Caen Lower Normandy"
    ,"University of Castilla–La Mancha"
    ,"Czech Technical University in Prague"
    ,"Saitama Medical University"
    ,"Istanbul Technical University"
    ,"Kaohsiung Medical University"
    ,"University of Electro-Communications"
    ,"Yeungnam University"
    ,"Jawaharlal Nehru Centre for Advanced Scientific Research"
    ,"Putra University, Malaysia"
    ,"Institut national des sciences Appliquées de Lyon"
    ,"University of Perpignan Via Domitia"
    ,"University of Miyazaki"
    ,"Second Military Medical University"
    ,"Northern Arizona University"
    ,"École Polytechnique de Montréal"
    ,"University of Salento"
    ,"Fukuoka University"
    ,"Nagoya Institute of Technology"
    ,"Ankara University"
    ,"Blaise Pascal University"
    ,"University of Electronic Science and Technology of China"
    ,"National Sun Yat-sen University"
    ,"University of Rouen"
    ,"Bowling Green State University"
    ,"Norwegian University of Life Sciences"
    ,"University of Picardie Jules Verne"
    ,"Michigan Technological University"
    ,"University of Concepción"
    ,"China Medical University (Taiwan)"
    ,"University of Hohenheim"
    ,"Aberystwyth University"
    ,"Wilfrid Laurier University"
    ,"Cairo University"
    ,"University of Murcia"
    ,"Palacký University, Olomouc"
    ,"Taipei Medical University"
    ,"University of Bradford"
    ,"King Fahd University of Petroleum and Minerals"
    ,"Ege University"
    ,"University of Belgrade"
    ,"University of Eastern Piedmont Amedeo Avogadro"
    ,"Budapest University of Technology and Economics"
    ,"University of Salzburg"
    ,"University of Massachusetts Lowell"
    ,"Jaume I University"
    ,"Chongqing University"
    ,"Beijing Institute of Technology"
    ,"Manchester Metropolitan University"
    ,"Zhengzhou University"
    ,"Dublin City University"
    ,"National University of Ireland, Maynooth"
    ,"Kangwon National University"
    ,"Royal Veterinary College, University of London"
    ,"Wright State University - Dayton"
    ,"Capital Medical University"
    ,"University of Brighton"
    ,"Sofia University"
    ,"University of Regina"
    ,"Magna Græcia University of Catanzaro"
    ,"Chungbuk National University"
    ,"Kōchi University"
    ,"University of New Brunswick"
    ,"Sejong University"
    ,"Sharif University of Technology"
    ,"Chung-Ang University"
    ,"Queens College, City University of New York"
    ,"Murdoch University"
    ,"Chosun University"
    ,"Vilnius University"
    ,"University of Maribor"
    ,"Ritsumeikan University"
    ,"University of Sassari"
    ,"Nanjing Normal University"
    ,"University of Franche-Comté"
    ,"University of Texas at El Paso"
    ,"Rio de Janeiro State University"
    ,"Jinan University"
    ,"University of Massachusetts Boston"
    ,"University of Alcalá"
    ,"Tehran University of Medical Sciences"
    ,"University of the Ryukyus"
    ,"University of Angers"
    ,"Fluminense Federal University"
    ,"Florida Institute of Technology"
    ,"Trent University"
    ,"National Chung Cheng University"
    ,"Bilkent University"
    ,"National Taiwan Normal University"
    ,"Saitama University"
    ,"RMIT University"
    ,"Hamamatsu University School of Medicine"
    ,"University of Greifswald"
    ,"University of Pécs"
    ,"University of Fukui"
    ,"Oakland University"
    ,"Nanjing University of Technology"
    ,"University of Technology, Sydney"
    ,"University of North Carolina at Greensboro"
    ,"All India Institute of Medical Sciences, New Delhi"
    ,"Kyoto Institute of Technology"
    ,"Ocean University of China"
    ,"Chiang Mai University"
    ,"University of Camerino"
    ,"Hirosaki University"
    ,"University of North Dakota"
    ,"University of Extremadura"
    ,"Teikyo University"
    ,"Jiangnan University"
    ,"Adam Mickiewicz University in Poznań"
    ,"University of Waikato"
    ,"University of Veterinary Medicine Vienna"
    ,"University of Cádiz"
    ,"Florida Atlantic University"
    ,"Huazhong Agricultural University"
    ,"Brock University"
    ,"University of Lleida"
    ,"Shanghai Normal University"
    ,"Jean Monnet University"
    ,"Shimane University"
    ,"University of Orléans"
    ,"University of Siegen"
    ,"Makerere University"
    ,"University of Wales, Trinity Saint David"
    ,"National Taiwan University of Science and Technology"
    ,"University of Brasília"
    ,"University of Western Brittany"
    ,"Nanjing Agricultural University"
    ,"National University of Córdoba"
    ,"Federal University of Santa Catarina"
    ,"National Taiwan Ocean University"
    ,"Gazi University"
    ,"National University of Colombia"
    ,"University of Jaén"
    ,"Boğaziçi University"
    ,"Jadavpur University"
    ,"Pukyong National University"
    ,"University of Western Sydney"
    ,"Northeastern University (China)"
    ,"University of Mannheim"
    ,"University of Osnabrück"
    ,"Charles Darwin University"
    ,"University of Hyderabad"
    ,"Northwest University (China)"
    ,"University of Southern Mississippi"
    ,"University of Wrocław"
    ,"Carlos III University of Madrid"
    ,"Novosibirsk State University"
    ,"Nanjing Medical University"
    ,"Nicolaus Copernicus University in Toruń"
    ,"Federal University of Santa Maria"
    ,"Chemnitz University of Technology"
    ,"Southwest University"
    ,"Wrocław University of Technology"
    ,"University of California, Merced"
    ,"United Arab Emirates University"
    ,"Federal University of São Carlos"
    ,"University of Thessaly"
    ,"University of Basilicata"
    ,"Feng Chia University"
    ,"Federal University of Paraná"
    ,"University of Almería"
    ,"Hallym University"
    ,"Fourth Military Medical University"
    ,"University of the Republic (Uruguay)"
    ,"Dokuz Eylül University"
    ,"Nanjing University of Aeronautics and Astronautics"
    ,"Public University of Navarre"
    ,"University of A Coruña"
    ,"Isfahan University of Technology"
    ,"University of New England (Australia)"
    ,"Federal University of Pernambuco"
    ,"Jiangsu University"
    ,"Third Military Medical University"
    ,"South China Normal University"
    ,"University of Limerick"
    ,"Xiangtan University"
    ,"Yangzhou University"
    ,"Northwestern Polytechnical University"
    ,"University of Reims Champagne-Ardenne"
    ,"Ibaraki University"
    ,"Chung Shan Medical University"
    ,"Alexandria University"
    ,"Yuan Ze University"
    ,"University of Cyprus"
    ,"University of Silesia"
    ,"Southern Medical University"
    ,"Shiraz University"
    ,"University of Bucharest"
    ,"Mansoura University"
    ,"Amirkabir University of Technology"
    ,"Beijing Jiaotong University"
    ,"University of Valenciennes and Hainaut-Cambresis"
    ,"University of the Algarve"
    ,"University of Chemistry and Technology, Prague"
    ,"Federal University of Ceará"
    ,"Ain Shams University"
    ,"University of Oldenburg"
    ,"Federal University of Bahia"
    ,"Hunan Normal University"
    ,"Western Michigan University"
    ,"University of Seoul"
    ,"Federico Santa María Technical University"
    ,"Ryerson University"
    ,"South China Agricultural University"
    ,"Fu Jen Catholic University"
    ,"China Pharmaceutical University"
    ,"Tarbiat Modares University"
    ,"University of Puerto Rico at Mayagüez"
    ,"National University of Rosario"
    ,"University of Pau and Pays de l'Adour"
    ,"Xidian University"
    ,"Zhejiang University of Technology"
    ,"China Medical University (PRC)"
    ,"Federal University of ABC"
    ,"King Juan Carlos University"
    ,"Beijing University of Technology"
    ,"Guangxi University"
    ,"Northwest A&F University"
    ,"Shanxi University"
    ,"Tamkang University"
    ,"Harbin Medical University"
    ,"Zhejiang Normal University"
    ,"King Abdulaziz University"
    ,"Iran University of Science and Technology"
    ,"I-Shou University"
    ,"University of Maine (Le Mans)"
    ,"National Dong Hwa University"
    ,"National Taipei University of Technology"
    ,"Shaanxi Normal University"
    ,"National University of Defense Technology"
    ,"Yanbian University"
    ,"University of Montpellier"
    ,"Moscow Institute of Physics and Technology"
    ,"University of Bordeaux"
    ,"University of Oklahoma Health Sciences Center"
    ,"Renmin University of China"
    ,"École normale supérieure de Cachan"
    ,"National Research Nuclear University MEPhI"
    ,"Jacobs University Bremen"
    ,"Other"
]
