import { useEffect, useState } from 'react';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Divider from '@mui/joy/Divider';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import Card from '@mui/joy/Card';
import { signOut } from "firebase/auth";
import Autocomplete from '@mui/joy/Autocomplete';
import { possibleOccupations, possibleUniversity } from '../Data/database';
import { useNavigate } from 'react-router-dom';
import Table from '@mui/joy/Table';
import TableContainer from '@mui/material/TableContainer';


import { auth } from '../firebase';
import { db } from '../firebase';
import { doc, getDoc, query } from "firebase/firestore";


function Account() {
  const [userData, setUserData] = useState({})
  const navigate = useNavigate();

  async function getUserData() {
    auth.onAuthStateChanged(async (user) => {
      if(user !== null && user.displayName === null){
        navigate("/verify")
      }

      if(user !== null && user.emailVerified === false){
        signOut(auth).then((event) => {
          console.log(event)
          // Sign-out successful.
          // go to page notverified
          navigate("/notverified")
        })
      }

      await getDoc(doc(db, "users", user.uid)).then((doc) => {
        console.log(doc.data());
        if (doc.exists()) {
          setUserData(doc.data());

          Object.entries(userData["accessibleCourses"]).map(([courseName, expireDate]) => {
            // const expireDateTime = new Date(expireDate);
            console.log(courseName, expireDate.toDate().toLocaleString('en-US', { dateStyle: 'short', timeStyle: 'short' }));
          })
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      }).catch((error) => {
        console.log("Error getting document:", error);
      });
    });
  }

  function isCourseExpired(date) {
    const currentDate = new Date();
    return currentDate > date;
  }

  useEffect(() => {
    getUserData()
  }, [])


  return (
      <Box
        sx={{
          flex: 1,
          width: '100%',
        }}
      >  
        <Stack
          spacing={4}
          sx={{
            display: 'flex',
            maxWidth: '800px',
            mx: 'auto',
            px: {
              xs: 2,
              md: 6,
            },
            py: {
              xs: 2,
              md: 3,
            },
          }}
        >
          <Card>
            <Box sx={{ mb: 1 }}>
              <Typography level="title-md">Personal info</Typography>
              <Typography level="body-sm">
                Customize how your profile information will apper to the networks.
              </Typography>
            </Box>
            <Divider />
            <Stack spacing={2} sx={{ flexGrow: 1 }}>
              <FormControl>
                <FormLabel>Full Name</FormLabel>
                <Input size="sm" placeholder="Full Name" name="fullname" value={userData.fullName !== undefined ? userData.fullName : ''}/>
              </FormControl>
              <FormControl>
                <FormLabel>Email</FormLabel>
                <Input size="sm" placeholder="Email" name="email" value={userData.email !== undefined ? userData.email : ''}/>
              </FormControl>
              <FormControl>
                  <FormLabel>Date of Birth</FormLabel>
                  <Input
                      type="date"
                      name="dob"
                      value={userData.dob !== undefined ? userData.dob : ''}
                  />
              </FormControl>
              <FormControl>
                  <FormLabel>Occupation</FormLabel>
                  <Autocomplete
                      placeholder="Student"
                      options={possibleOccupations}
                      type="occupation" name="occupation"
                      value={userData.occupation !== undefined ? userData.occupation : ''}
                  />
              </FormControl>
              <FormControl>
                  <FormLabel>University</FormLabel>
                  <Autocomplete
                      placeholder="Harvard University"
                      options={possibleUniversity}
                      type="university" name="university"
                      value={userData.university !== undefined ? userData.university : ''}
                  />
              </FormControl>
            </Stack>
          </Card>
        </Stack>
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <Typography level="h1" >
            Your Courses
          </Typography>
        </Box>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table
            aria-label="basic table"
            size="lg"
            variant="soft"
            stripe="even"
            sx={{
              mx: 'auto', // margin left & right
              my: 4, // margin top & bottom
              py: 3, // padding top & bottom
              px: 2, // padding left & right
              maxWidth: 800, // set max width to 800
            }}
          >
            <thead style={{ position: 'sticky', top: 0 }}>
              <tr>
                <th>Course Name</th>
                <th>Expiration Date</th>
                <th>Purchase Date</th> {/* Add new column for purchase date */}
                <th>Status</th> {/* Add new column for status */}
              </tr>
            </thead>
            <tbody>
              {userData["accessibleCourses"] != null && Object.entries(userData["accessibleCourses"]).map(([courseName, expireDate]) => {
                const purchaseDate = new Date(expireDate.toDate());
                purchaseDate.setDate(purchaseDate.getDate() - 14); // Subtract 14 days from expiration date
                return (
                  <tr key={courseName}>
                    <td>{courseName}</td>
                    <td>{expireDate.toDate().toLocaleString('en-US', { dateStyle: 'short', timeStyle: 'short' })}</td>
                    <td>{purchaseDate.toLocaleString('en-US', { dateStyle: 'short', timeStyle: 'short' })}</td>
                    <td style={{ color: isCourseExpired(expireDate.toDate()) ? 'red' : 'green' }}>
                      {isCourseExpired(expireDate.toDate()) ? 'Expired' : 'Active'}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </TableContainer>
      </Box>
  );
}

export default Account;