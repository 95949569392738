import * as React from 'react';
import Box from '@mui/joy/Box';
import IconButton from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';
import Input from '@mui/joy/Input';
import { Link } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import Button from '@mui/joy/Button';
import FaceIcon from '@mui/icons-material/Face';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { auth } from '../firebase';
import { signOut } from "firebase/auth";

import { useNavigate } from 'react-router-dom';


export default function NavBar() {
  const [user, setUser] = React.useState(null);

  const navigate = useNavigate();

  React.useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
      if(user !== null && user.displayName === null){
        navigate("/verify")
      }
      
      if(user !== null && user.emailVerified === false){
        signOut(auth).then((event) => {
          console.log(event)
          // Sign-out successful.
          setUser(null);
          // go to page notverified
          navigate("/notverified")
        })
      }
    });

    if(user !== null && user.displayName === null){
      navigate("/verify")
    }

    return unsubscribe;
  }, []);

  //Sign out here firebase auth
  const handleSignOut = () => {
    console.log("Handle sign out")
    signOut(auth).then((event) => {
      console.log(event)
      // Sign-out successful.
      setUser(null);
    }
    ).catch((error) => {
      // An error happened.
      console.log(error);
    });
  }


  return (
    <Box
      sx={{
          p: 2,
          gap: 2,
          bgcolor: 'background.surface',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          gridColumn: '1 / -1',
          borderBottom: '1px solid',
          borderColor: 'divider',
          position: 'sticky',
          top: 0,
          zIndex: 1100,
        }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 1.5
        }}
      >
        <IconButton
          size="sm"
          variant="soft"
          sx={{ display: { xs: "none", sm: "inline-flex" } }}
          component={Link}
          to="/"
        >
          <HomeIcon />
        </IconButton>
        <Typography component="h1" fontWeight="xl">
          Exam Eve
        </Typography>
      </Box>
      {/* <Input
        size="sm"
        variant="outlined"
        placeholder="Search anything…"
        startDecorator={<SearchRoundedIcon color="primary" />}
        endDecorator={
          <IconButton variant="outlined" color="neutral">
            <Typography fontWeight="lg" fontSize="sm" textColor="text.icon">
              ⌘ + k
            </Typography>
          </IconButton>
        }
        sx={{
          flexBasis: "500px",
          display: {
            xs: "none",
            sm: "flex"
          },
          boxShadow: "sm"
        }}
        disabled
      /> */}
      <Box sx={{ display: "flex", flexDirection: "row", gap: 1.5 }}>
        {
          user===null ?
            <>
              <Button 
                variant="soft"
                component={Link}
                to="/signin"
              >
                Sign In
              </Button>
              <Button 
                variant="solid"
                component={Link}
                to="/signup"
              >
                Sign Up
              </Button>
            </>
          :
          <>
            {/* <Button 
                variant="soft"
                component={Link}
                to="/redeem"
            >
              Redeem
            </Button> */}
            <Button 
                variant="soft"
                component={Link}
                to="/myexams"
              >
                My Exams
              </Button>
            <IconButton
              size="sm"
              variant="soft"
              color="neutral"
              component={Link}
              to="/account"
            >
              <FaceIcon />
            </IconButton>
            <IconButton
              size="sm"
              variant="soft"
              color="neutral"
              onClick={handleSignOut}
            >
              <ExitToAppIcon />
            </IconButton>
          </>
        }
      </Box>
    </Box>
  );
}
