import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import FormControl from '@mui/joy/FormControl';
import FormLabel, { formLabelClasses } from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Typography from '@mui/joy/Typography';
import { useNavigate } from 'react-router-dom';
import Autocomplete from '@mui/joy/Autocomplete';


import { doc, setDoc, serverTimestamp } from "firebase/firestore";
import { db } from '../firebase';
import { possibleOccupations, possibleUniversity } from '../Data/database';
import { auth } from '../firebase';
import { updateProfile } from "firebase/auth";


export default function FinishSignUp() {
    const navigate = useNavigate();

    const handleUserDataUpload = async (form) => {
        const email = auth.currentUser.email;
        const uid = auth.currentUser.uid;
        console.log(form)
        try {
            await setDoc(doc(db, "users", uid), {
                fullName: form.fullname,
                email: email,
                occupation: form.occupation,
                university: form.university,
                createdAt: serverTimestamp(),
                dob: form.dob,
                accessibleCourses: {}
            });

            await updateProfile(auth.currentUser, {
                displayName: form.fullname,
                email: email
            });

            await navigate("/");
        } catch (error) {
            console.error("Error writing document: ", error);
        }
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Box
            sx={{
                    height: '100%',
                    position: 'fixed',
                    right: 'clamp(0px, (100vw - var(--Collapsed-breakpoint)) * 999, 100vw - var(--Cover-width))',
                    top: 0,
                    bottom: 0,
                    left: 0,
                    zIndex: 1,
                    display: 'flex',
                    justifyContent: 'flex-end',
                    backdropFilter: 'blur(4px)',
                    backgroundColor: 'white'
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        minHeight: '100dvh',
                        width:
                            'clamp(var(--Form-maxWidth), (var(--Collapsed-breakpoint) - 100vw) * 999, 100%)',
                        maxWidth: '100%',
                        px: 10,
                    }}
                >
                    <Box
                        component="header"
                        sx={{
                            py: 3,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Typography
                            fontWeight="lg"
                            startDecorator={
                                <Box
                                    component="span"
                                    sx={{
                                        width: 24,
                                        height: 24,
                                        background: (theme) =>
                                            `linear-gradient(45deg, ${theme.vars.palette.primary.solidBg}, ${theme.vars.palette.primary.solidBg} 30%, ${theme.vars.palette.primary.softBg})`,
                                        borderRadius: '50%',
                                        boxShadow: (theme) => theme.shadow.md,
                                        '--joy-shadowChannel': (theme) =>
                                            theme.vars.palette.primary.mainChannel,
                                    }}
                                />
                            }
                        >
                            Exam Eve
                        </Typography>
                    </Box>
                    <Box
                        component="main"
                        sx={{
                            my: 'auto',
                            py: 2,
                            pb: 5,
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 2,
                            width: 400,
                            maxWidth: '100%',
                            mx: 'auto',
                            borderRadius: 'sm',
                            '& form': {
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 2,
                            },
                            [`& .${formLabelClasses.asterisk}`]: {
                                visibility: 'hidden',
                            },
                        }}
                    >
                        <div>
                            <Typography component="h1" fontSize="xl2" fontWeight="lg">
                                Finish Sign Up
                            </Typography>
                        </div>
                        <form onSubmit={(event) => {
                                event.preventDefault();
                                const formElements = event.currentTarget.elements;
                                const data = {
                                    fullname: formElements.fullname.value,
                                    occupation: formElements.occupation.value,
                                    university: formElements.university.value,
                                    dob: formElements.dob.value,
                                };
                                handleUserDataUpload(data)
                            }}
                        >
                            <FormControl required>
                                <FormLabel>Full Name</FormLabel>
                                <Input type="fullname" name="fullname" fullWidth/>
                            </FormControl>
                            <FormControl required>
                                <FormLabel>Date of Birth</FormLabel>
                                <Input
                                    type="date"
                                    slotProps={{
                                        input: {
                                            min: '2018-06-07T00:00',
                                            max: '2018-06-14T00:00',
                                            },
                                    }}
                                    name="dob"
                                />
                            </FormControl>
                            <FormControl required>
                                <FormLabel>Occupation</FormLabel>
                                <Autocomplete
                                    placeholder="Student"
                                    options={possibleOccupations}
                                    type="occupation" name="occupation"
                                />
                            </FormControl>
                            <FormControl required>
                                <FormLabel>University</FormLabel>
                                <Autocomplete
                                    placeholder="Harvard University"
                                    options={possibleUniversity}
                                    type="university" name="university"
                                />
                            </FormControl>
                            <Button type="submit" fullWidth>
                                Finish Sign Up
                            </Button>
                        </form>
                    </Box>
                    <Box component="footer" sx={{ py: 3 }}>
                        <Typography level="body-xs" textAlign="center">
                            © Exam Eve {new Date().getFullYear()}
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Box
                sx={{
                    height: '100%',
                    position: 'fixed',
                    right: 0,
                    top: 0,
                    bottom: 0,
                    left: 560,
                    transition: 'background-image var(--Transition-duration), left var(--Transition-duration) !important',
                    transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
                    backgroundColor: 'background.level1',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center center',
                    backgroundRepeat: 'no-repeat',
                    backgroundImage: 'url(https://images.unsplash.com/photo-1527181152855-fc03fc7949c8?auto=format&w=1000&dpr=2)'
                }}
            />
        </Box>
    )
}