import * as React from 'react';

//Material UI components
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Divider from '@mui/joy/Divider';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import Card from '@mui/joy/Card';
import CardActions from '@mui/joy/CardActions';
import CardOverflow from '@mui/joy/CardOverflow';
import Chip from '@mui/joy/Chip';

function QuestionAnswerBox({ courseId, question, number, answer }) {
  const [userWantsToCheck, setUserWantsToCheck] = React.useState(false);

  const handleReveal = () => {
    setUserWantsToCheck(true);
  }

  React.useEffect(() => {
    console.log(courseId, question, number, answer)
  }, []);

  return (
    <Box
        sx={{
          flex: 1
        }}
      >  
        <Stack
          spacing={4}
          sx={{
            display: 'flex',
            maxWidth: '1200px',
            // mx: 'auto',
            px: {
              xs: 2,
              md: 6,
            },
            py: {
              xs: 2,
              md: 3,
            },
          }}
        >
          <Card >
            <Box sx={{ mb: 1 }}>
              <Typography level="title-sg" sx={{ fontWeight: 'bold' }}>Question {number}</Typography>
              <Typography level="body-md">
                <span dangerouslySetInnerHTML={{ __html: question }} />
              </Typography>
            </Box>
            <CardOverflow sx={{ borderTop: '1px solid', borderColor: 'divider' }}>
              <CardActions sx={{ alignSelf: 'flex-end', pt: 2 }}>
                {
                  userWantsToCheck &&
                    <Chip
                      color="success"
                      size="lg"
                      variant="solid"
                    >
                      {answer}
                    </Chip>
                }
                <Button size="sm" variant="solid" onClick={handleReveal}>
                  Reveal Answer
                </Button>
              </CardActions>
            </CardOverflow>
          </Card>
        </Stack>
      </Box>
  );

}
export default QuestionAnswerBox;