import { useState, useEffect } from "react";

import { db } from '../firebase';
import { getDocs, collection, startAfter, limit, query, orderBy, where } from "@firebase/firestore"

import { useParams, useLocation, useNavigate } from "react-router-dom";

import Stack from '@mui/material/Stack';
import Button from '@mui/joy/Button';

//Question Types
import QuestionBox from "../components/QuestionBox";
import MultipleChoiceQuestion from "../components/MultipleChoiceQuestion";
import FillInTheBlanksQuestion from "../components/FillInTheBlanksQuestion";
import PassageBox from "../components/PassageBox";
import QuestionAnswerBox from "../components/QuestionAnswerBox";

import Input from '@mui/joy/Input';
import FormControl from '@mui/joy/FormControl';


function PassagePage(){
    const navigate = useNavigate();
    const params = useParams();
    const location = useLocation();

    const [allPassages, setAllPassages] = useState({});
    const [allQuestions, setAllQuestions] = useState({}); 
    const [pageInput, setPageInput] = useState(params.page);

    const getQuestions = async () => {
        let tempAllPassages = {}
        let tempAllQuestions = {}

        if(location.hasOwnProperty("state") && location.state!== null){
            tempAllQuestions = location.state.allQuestions
            tempAllPassages = location.state.allPassages
        }
        
        if(!tempAllQuestions.hasOwnProperty(params.page) && !tempAllPassages.hasOwnProperty(params.page)){
            let finalData = []

            const passage_ref = query(collection(db, "courses/"+params.courseId+"/passages"), orderBy("number"), startAfter((parseInt(params.page) - 1)), limit(1));
            const querySnapshot_passage = await getDocs(passage_ref);

            await querySnapshot_passage.docs.map(async (doc) => {
                tempAllPassages[params.page] = {passage_id: doc.id, ...doc.data()}
            });

            const question_ref = query(collection(db, "courses/"+params.courseId+"/questions"), where("passage_id", "==", tempAllPassages[params.page].passage_id));
            const querySnapshot_question = await getDocs(question_ref);

            await querySnapshot_question.docs.map(async (doc) => {
                finalData.push({courseId: doc.id, ...doc.data()})
            });

            tempAllQuestions[params.page] = finalData
            setAllQuestions(tempAllQuestions)
            setAllPassages(tempAllPassages)
        }
        else
        {
            setAllQuestions(tempAllQuestions)
            setAllPassages(tempAllPassages)
        }
    };

    useEffect(() => {
        const url = window.location.pathname;
        setPageInput(url.substring(url.lastIndexOf('/') + 1))

        getQuestions();
    }, [params.page]);

    const handleNext = () => {
        navigate("/passage/" + params.courseId + "/" + String(parseInt(params.page)+1), {state:{
            courseId: params.courseId,
            allQuestions: allQuestions,
            allPassages: allPassages
        }})

        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }

    const handlePrevious = () => {
        navigate("/passage/" + params.courseId + "/" + String(parseInt(params.page)-1), {state: {
            courseId: params.courseId,
            allQuestions: allQuestions,
            allPassages: allPassages
        }})

        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }

    function handlQuestionType(question){
        if(question.type === 0){
            return <QuestionBox 
                        courseId={question.courseId} 
                        question={question.question} 
                        options={question.options} 
                        answers={question.answers} 
                        question_images={question.question_images}
                        number={question.number}
                    />
        }
        else if(question.type === 1){
            return <MultipleChoiceQuestion 
                        courseId={question.courseId} 
                        question={question.question} 
                        options={question.options} 
                        answers={question.answers} 
                        question_images={question.question_images}
                        number={question.number}
                    />
        }
        else if(question.type === 2){
            return <QuestionAnswerBox
                        courseId={question.courseId} 
                        question={question.question} 
                        answer={question.answers} 
                        number={question.number}
                    />
        }
        else{
            return <FillInTheBlanksQuestion
                        courseId={question.courseId} 
                        question={question.question} 
                        options={question.options} 
                        answers={question.answers} 
                        question_images={question.question_images}
                        number={question.number}
                    />
        }
    }

    return(
        <div style={{ display: "flex", flexDirection: "column", gap: "10px"}}>
            <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={2}
            >
                {
                    allPassages.hasOwnProperty(params.page) &&
                    <PassageBox 
                        passageId={params.page} 
                        passage={allPassages[params.page].passage} 
                        number={allPassages[params.page].number}
                    />
                }
                {
                    allQuestions.hasOwnProperty(params.page) &&
                        allQuestions[params.page].map((question, index) => (
                            <div key={index}>
                                {handlQuestionType(question)}
                            </div>
                        ))
                }
            </Stack>
            <div style={{ display: "flex", justifyContent: "center", marginBottom: "20px" }}>
                <Button variant="contained" color="primary" onClick={handlePrevious} disabled={parseInt(params.page)===1}>Previous</Button>
                <FormControl sx={{mx: 3}}>
                    <Input
                        sx={{ '--Input-decoratorChildHeight': '45px', width: 120, textAlign: "center" }}
                        type="text"
                        value={pageInput}
                        required
                        onChange={(event) =>
                            setPageInput(event.target.value)
                        }
                        endDecorator={
                            <Button
                                variant="solid"
                                color="primary"
                                type="submit"
                                sx={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                                onClick={()=> {
                                    navigate("/" + params.courseId + "/" + pageInput, {
                                        courseId: params.courseId,
                                        questions: null
                                    })
                                }}
                            >
                            Go
                            </Button>
                        }
                    />
                </FormControl>
                <Button variant="contained" color="primary" onClick={handleNext}>Next</Button>
            </div>
        </div>
    )
}

export default PassagePage;